.section-info-ambiental-rojales,
.section-consultas {
    background-color: #0c85d6 !important;
}

.section-info-ambiental-rojales .card{
    margin: 2%;
}

.section-info-ambiental-rojales{
    background-color: #0c85d6 !important;
}

.title-valverde-2{
    text-align: center;
    color: white;
    font-weight: bold;
    padding-top: 2%;
}

.section-info-ambiental-rojales .container{
    margin-top: 0rem !important;
}

.tit-ordenanzas-rojales{
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    padding: 2%;
}

.src-ordenanzas-rojales{
    width: 42%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.ordenanzas-rojales{
    margin-bottom: 4%;
}

.ordenanzas-rojales .list-group-item:hover{
    background-color: #0c85d6;
    color: white;
    transition: 0.5s;
    cursor: pointer;
}
