.header{
    font-size: 2.10em;
}
.titulo1{
    font-size: 1.75em;
    margin-top: 2%;
}
.parrafo{
    margin-top: 2%;
    margin-left: 8%;
    text-align: justify;
}
.titulo2{
    font-size: 1.5em;
    margin-top: 2%;
}

ul{
    margin-left: 10%;
}
.firma{
    text-align: right;
}