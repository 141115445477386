


.GamesBoxCh .card{
    background-color:#a50050;
    color: white;
    width: 21rem !important;
    margin-top: 5% !important;
    border:0px solid rgba(0,0,0,.125);
}

.GamesBoxCh img{
    width: 100%;
    height: 107%;
}

.GamesBoxCh{
    align-content: center;
    color: white;
    text-align: center;
    padding-top: 5%;
    
}

.GamesBoxCh .btn-primary {
    background-color: #d61b76;
    border-color:#a50050;
}

.GamesBoxCh .btn-primary:hover, .GamesBoxCh .btn-primary:focus {
    background-color:#d14488;
    border-color: #a50050;
}

.GamesBoxCh .card-title {
    text-align: center;
}

.GamesBoxCh .card-deck {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GamesBoxCh .card {
    margin-bottom: 15px;
    
}