#poi-category{    
    margin-top: 5px;
}

#poi-category .category-img{
    height: 3em;
    width: 3em;
    object-fit: cover;
    border-radius: 5px;
    padding: 0.3em;
}

#poi-category .category-title{
    margin-left: 0.5em;
    font-size: 1.5em;
    font-weight: bold;
}

#poi-category .category-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparents;    
    border-radius: 5px;
    color: #313131;
}

#poi-category .category-line{
    width: 100%;
    height: 4px;
    background: #313131;
    border-radius: 2px;
}

#poi-category hr {
    margin-top: 20px;
    margin-bottom: 10px;
}
