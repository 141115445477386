.extendsCard img {
    width: 80% !important;
    height: auto;
    display: block;
    margin: auto;
}

.extendsCard p{
    padding: 1%;
}

.extendsCard .card-body {
    color: white;
    background-color: #00aead;
    height: 100%;
    width: 100%;
}

.extendsCard .card-header {
    color: white;
    background-color: #00aead;
    text-align: center;
}
.extendsCard .card {
    border: 0px;
    margin: 4%;
}

.extendsCard .card-body a{
    color: white;
}

.extendsCard .card-body .btn-primary {
    color: #fff;
    background-color: #00aead;
    border-color: white;
}

.extendsCard .card-body .btn-primary:hover {
    color: #00aead;
    background-color: white;
    border-color: white;
}

