#survey-page .card {
    margin-top: 1rem !important;
}

#survey-page .info-error{
    /*border: solid 1px #e8e8e8; */
   border-radius: 5px;
   background-color: white;
   transition: top 0.2s;
   transition: -webkit-box-shadow 0.2s;
   transition: -moz-box-shadow 0.2s;
   transition: box-shadow 0.2s;
   position: relative; 
   top: 0px;
   -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
   -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
   box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
   margin: 0 !important;
   text-align: center;
   font-size: 30px;
   font-weight: bold;
   margin-top: 1em !important;
   min-height: 4em;
   padding: 1.5em;
   width: 90%;
}


#survey-page .col-left {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 1em;
    overflow-y: auto;
}

#survey-page .col-right {
    padding-right: 2em;
    padding-left: 2em;
    padding-top: 1.6em;
    height: 100vh;
    margin-bottom: 5%;
    overflow-y: auto;
    padding-bottom: 4%;
    /* padding-top:4%; */
}

.spinner-survs {
    margin: 5% 50%;
}

@media only screen and (max-width: 1500px) {
    #survey-card .dates {
        display: -webkit-flex;
        display: none;
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 266px;
        -webkit-justify-content: center;
        justify-content: center;
    }
}

@media only screen and (min-width: 480px) {
    #survey-page .col-left {
        padding-right: 2em;
    }
}

@media only screen and (min-width: 1200px) {
    #survey-page .col-left {
        height: 100vh;
        padding-right: 1em;
        margin-bottom: 5%;
    }
}

@media(max-width:750px) {
    .survey .status {
        display: none !important;
    }

    #survey-page .card-deck {
        padding-left: 0% !important;
    }
}

#survey-page .card-deck {
    padding-left: 10%;
}

.container-survey-detail {
    margin-left: 0%;
    margin-right: 0% !important;
    padding-left: 0%;
    padding-right: 0%;
    width: 100%;
    max-width: 100% !important;
}