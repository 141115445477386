.EnvEducTv {

    align-content: center;
    color: black !important;
    text-align: center;
    padding-bottom: 4%;
    background-color: #f2f4f4;

    .infoTit {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 212%;
        padding: 3% 0% 1% 0%;
    }
    .line {
        border-color: #60912a !important;
    }

    .card {
        color: black !important;
        width: 21rem !important;
        height: 21rem !important;
        border: 0px !important ;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    }


    img {
        width: 34%;
        margin: auto;
    }

    .btn-primary {
        background-color: white;
        border-color: #60912a;
        color:#60912a;
    }

    .btn-primary:hover,
    .btn-primary:focus {
        background-color: #60912a;
        border-color: #60912a;
        color:white
    }

    .card-title {
        text-align: center;
    }

    .card-deck {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        margin-bottom: 15px;

    }
    

}