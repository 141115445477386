.content-searcher {
    display: flex;
    align-items: center;
}


.content-searcher svg {
    color: white;
    margin-right: 1rem;
    margin-left: -17px;
}

.content-searcher .form-control {
    background-color: transparent;
    border: none;
    border-bottom: solid 2px white;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;
    color: white;
}

.content-searcher input[type="text"]::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.76);
}

.content-searcher .result-container {
    position: absolute;
    left: 0;
    background-color: white;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 18px 30px -22px #000000;
}

.content-searcher .result-container h5 {
    color: #008f4c;
}

.content-searcher .result-item {
    cursor: pointer;
    font-weight: 500;
    padding-left: 1rem;
}

.content-searcher .no-result-item {
    font-weight: 500;
    padding-left: 1rem;
}

.result-container {
    overflow-y: scroll;
    max-height: 350px;
}