#incidents-page .card {
    margin-top: 1rem !important;
}


#incidents-page{
    padding-bottom: 1em !important;
    min-height: 60vh;
}

#incidents-page .col-left {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 1em;
    overflow-y: auto;
    max-height: 900px;
}

#incidents-page .col-right {
    padding-right: 2em;
    padding-left: 2em;
    padding-top: 1.6em;
    /* height: 100vh; */
   /* margin-bottom: 5%;*/
    overflow-y: auto;
   /*padding-bottom: 4%;*/
    /* padding-top:4%; */
}


#incidents-page .info{
     /*border: solid 1px #e8e8e8; */
    border-radius: 5px;
    background-color: white;
    transition: top 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: -moz-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    position: relative; 
    top: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    margin: 0 !important;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 1em !important;
    min-height: 2em;
    padding: 0.5em;
}

.spinner-incs {
    margin: 5% 50%;
}

@media only screen and (max-width: 1500px) {
    #incident-card .dates {
        display: -webkit-flex;
        display: none;
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 266px;
        -webkit-justify-content: center;
        justify-content: center;
    }
}

@media only screen and (min-width: 480px) {
    #incidents-page .col-left {
        padding-right: 2em;
    }
}
@media only screen and (min-width: 1200px) {
    #incidents-page .col-left {
        /* height: 100vh; */
        padding-right: 1em;
         /*margin-bottom: 5%; */
    }
}

/*
@media only screen and (min-width: 1583px) {

    .incident-pages-div{
        padding-top: 5%;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1583px)  {

    .incident-pages-div{
        padding-top: 4%;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1400px)  {

    .incident-pages-div{
        padding-top: 7%;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1300px)  {

    .incident-pages-div{
        padding-top: 10%;
    }
}

*/
@media only screen and (max-width: 800px)  {

    .incident-pages-div{
        padding-top: 7%;
    }

    #incidents-page .col-left{
        padding-top: 2em !important;
    }
}
/*
@media(max-width:750px){
    .incident .status{
        display: none !important;
    }
}
*/