.rec-box-med-pr{
    background-color: #8B1E3E;
    margin: 5%;
    height: 80%;
    color: white;
    font-size: 2em;
    cursor: pointer;
    border-radius: 5%;
}

.rec-box-med-t:hover{
    background-color: #b63159;
}

.rec-box-img{
    width: 30%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    
}

.title-box-med{
    padding:4%;
    text-align: center;
    font-weight: bold;
}

.url-s-p-pr:hover {
    text-decoration: none;
}

.card-pr .card-img-top {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 6%;
}

.card-pr {
    background-color: #8B1E3E;
    color: white;
    text-align: center;
}

.card-pr .btn-primary {
    color: #fff;
    background-color: #bb3d63;
    border-color: #bb3d63;
}

.card-pr .btn-primary:hover {
    color: #fff;
    background-color: #852441;
    border-color: #bb3d63;
}

.cards-pr{
    margin: 5%;
}

.envi-menu{
    padding-bottom: 5%;
}

.card-menu-pr-intro{
    background-color: #8B1E3E;
    color: white;
    text-align: center;
}

.title-menu-pr{
    padding: 2%;
}

.card-menu-pr-rosario {
    color: white;
    text-align: center;
    width: 100%;
    background-color:#8B1E3E ;
}