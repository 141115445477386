.card-incident-commet {
    /*
    padding: 1em;
    margin-top: 1em;
    */
    margin-bottom: 1em;
}


.coment-incident-body {
    overflow: auto;
    max-height: 70vh;
    background-color: #005e7d1a;
 }
 
.title-header-incident-commet .modal-header {
display: block !important;
}
.coment-incident-commet {
    width: 90%;
    align-self: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
}
.coment-incident-footer {
    border-top: 5px solid #005e7d1a !important;
 }

.coment-incident-commet:focus {
 box-shadow: none;
 border-color: #0fc6b5;
}
.boton-incident-commet {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
    background-color: #198d8f;
    border-color: #198d8f;
}
.boton-incident-commet:hover {
    background-color: rgb(28, 108, 109);
    border-color: rgb(23, 99, 100);
    color: white;
}
.date-incident-commet {
    margin-left: 0.5em;
    font-size: 11px;
}
.profile-image-comment {
    width: 35px;
    height: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2rem;
}

.custom-user-comment {
    padding-top: 7px;
}