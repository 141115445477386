#nearby-incidents-page {
    padding-bottom: 1em;
}

#nearby-incidents-page .card {
    margin-top: 1rem !important;
}

#nearby-incidents-page .col-left {
    /* padding-left: 3em;
    padding-right: 2em; */
    margin-top: 1em;
    /* height: 100vh; */
    overflow-y: auto;
}

#nearby-incidents-page .col-right {
    padding-right: 2em;
    padding-top: 1em;
}

/******************** TABS ************************/
#nearby-incidents-page .nav-tabs {
    border-bottom: none;
    /* padding-bottom: 20px !important;
    padding-top: 20px !important; */

}

#nearby-incidents-page .nav-tabs a {
    margin-bottom: 0px;
    padding-left: 1em;
    padding-right: 1em;
    /*   
    padding: 0.1rem 0.6rem;
    color: white !important; */
}

#nearby-incidents-page .nav-link.active {
    background-color: transparent !important;
    color: #005E7D !important;
    border-bottom: solid 2px white;
    font-weight: bold;
    cursor: default;

}

#nearby-incidents-page .nav-link {
    background-color: transparent !important;
    color: #005E7D;
    border-bottom: solid 2px white;
    text-transform: uppercase;
    border: none;
    padding-top: 1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


#nearby-incidents-page .nav-link:not(.active):hover {
    background-color: #0098ca;
    color: white;
}

#nearby-incidents-page .tab-content {
    color: #005E7D !important;
    background-color: #d6e5ea !important;
    height: 100%;
    width: 100%;
    border-top-right-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;
}

#nearby-incidents-page .info-error {
    text-align: center;
    padding: 1em;
    font-size: 25px;
}

#nearby-incidents-page .col-left {
    min-height: 60vh;
}

/******************** TABS ************************/


.geolocation-info {
    color: white;
    font-size: 25px;
    text-align: center;
    padding-top: 1em;
}