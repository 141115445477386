


.GamesBox .card{
    background-color: #08B6B9;
    color: white;
    width: 21rem !important;
    margin-top: 5% !important;
    border:0px solid rgba(0,0,0,.125);
}

.GamesBox img{
    width: 100%;
    height: 107%;
}

.GamesBox{
    align-content: center;
    color: white;
    text-align: center;
    
}

.GamesBox .btn-primary {
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
}

.GamesBox .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.GamesBox .card-title {
    text-align: center;
}

.GamesBox .card-deck {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GamesBox .card {
    margin-bottom: 15px;
    
}