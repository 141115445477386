.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

#cc-main {
  /** Change font **/
  --cc-font-family: Roboto;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #01b1b2;
  --cc-btn-primary-border-color: #000000;
  --cc-btn-primary-hover-bg: #025555;
  --cc-btn-primary-hover-border-color: #000000;

  --cc-btn-secondary-bg: #01b1b2;
  --cc-btn-secondary-border-color: #000000;
  --cc-btn-secondary-hover-bg: #025555;
  --cc-btn-secondary-hover-border-color: #000000;

  --cc-btn-primary-color: #ffffff;
  --cc-btn-secondary-color: #ffffff;
  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 10px;
}