.imgGamesBox {
  width: 21em !important;
  height: 12em !important;
  object-fit: cover;
}
.GamesBoxTitulo {
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  --lh: 1.4rem;
  line-height: var(--lh);
}
.GamesBoxTexto {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.GamesBoxTexto::before {
  position: absolute;
  content: "...";
  /*   inset-block-end: 0;
    inset-inline-end: 0; */
  bottom: -6px;
  right: 0;
}
.GamesBoxTexto::after {
  content: "";
  position: absolute;
  /*   inset-inline-end: 0; */
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.module {
  width: 250px;
  margin: 0 0 1em 0;
  overflow: hidden;
}

.notice-part{
  background-color: #025555 !important;
}