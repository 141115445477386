.medioambiente-valverde{
    margin-top: 4%;
}

.valv-title{
    text-align: center;
    color: #262626;
    font-weight: bold;
    margin-top: 6em;
}

.collapse-valverde .nav-link.active{
    background-color: #759236;
    color: white !important;
}

.collapse-valverde .nav-pills .nav-link{
    color: #262626 ;
    font-weight: bold;
}