.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.code-example {
  background-color: #1e1e1e;
  color: #f0ff0f;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}