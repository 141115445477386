#banner-contact {
    height: 10rem;
    background-color: #025555;
}
#banner-contact .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
#banner-contact button {
    background-color: transparent;
    border: 2px solid white;
}
#banner-contact button:hover {
    background-color: white;
    color: #01b1b2;
}
#banner-contact p {
    color: white;
    font-size: xx-large;
    font-weight: 500;
    flex-grow: 2;
    margin-bottom: 0px;;
}
@media (min-width: 320px) and (max-width: 480px) {
    #banner-contact p {
        font-size: large;
        margin-left: 4%;
    }
}