.rec-box-med-pr{
    background-color: #8B1E3E;
    margin: 5%;
    height: 80%;
    color: black;
    font-size: 2em;
    cursor: pointer;
    border-radius: 5%;
}

.rec-box-med-t:hover{
    background-color: #b63159;
}

.rec-box-img{
    width: 30%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    
}

.title-box-med{
    padding:4%;
    text-align: center;
    font-weight: bold;
}


.url-s-p-pr:hover {
    text-decoration: none;
}

.card-ln .card-img-top {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 6%;
}

.card-ln {
    background-color: #009fe3;
    color: black;
    text-align: center;
    font-size: 1.5em;
}

.card-ln .btn-primary {
    color: #fff;
    background-color: #009fe3;
    border-color: #262626;
}

.card-ln .btn-primary:hover {
    color: black;
    background-color: #009fe3;
    border-color: #009fe3;
}

.cards-ln{
    margin: 5%;
}

.envi-menu{
    padding-bottom: 5%;
}

.card-menu-pr-intro{
    background-color: #009fe3;
    color: black;
    text-align: center;
    width: 100%;
}

.title-menu-pr{
    padding: 2%;
}

.infoBoxLnx2 .card-text {
    text-align: left;
    color: black;
}

@media (max-width: 1000px){
    .infoBoxLnx2 .card-text {
        text-align: left;
        color: black;
        height: 100%;
    }
}

@media (max-width: 768px){
    .card-deck .card {
        /* margin-left: auto; */
        /* margin-right: auto; */
        width: 100% !important;
        
    }
    .card-text{
        font-size: 0.8em !important;
    }
    .body-menu-pr{
        font-size: 1.1em !important;
    }
    .text-incident{
        font-size: 1.2em;
    }


}

.infoBoxLnx2 a {
    color: black;
    text-decoration: none !important;
}

.infoBoxLnx2 a:hover {
    color: rgb(204, 202, 202);  text-decoration: none; transition: all .3s;
}

.infoBoxLnx2 .card-body {
    background-color:#009fe3;
}

.download-store-btn-ln{
    padding: 3%;
}

.infoBoxLnx2 .row{
    margin-bottom: 0% !important;
}

.infoBoxLnx2 .card-header {
    background-color: #009fe3;
    color: black !important;
    text-align: center;
}

.infoBoxLnx2 img {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.row-inci-pr{
    width: 100% !important;
    text-align: center;
}


.infoBoxLnx2 .btn-outline-dark {
    color: black;
    border-color: white;
    margin: 3%;
}

.infoBoxLnx2 .btn-primary {
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
}

.infoBoxLnx2 .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.infoBoxLnx2 .card {
    margin-top: 0% !important;
    border: 0px solid rgba(0,0,0,0) !important;
    margin-bottom: 3%;
}

.infoBoxLnx2 {
    color: #262626;
    text-align: center;
}

.infoTit{
    padding: 5%;
}

.infoBoxLnx2 h5 {
    text-align: center;
}

* {
    font-family: 'Montserrat', sans-serif;
}

.download-app-pr h3{
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.btn-linea{
    background-color: black !important;
    color: white !important;
}

.fase-title-linea{
    color: white;
    font-size: 2.5em;
    font-weight: bolder;
    padding: 1%;
    text-align: center;
}

.linea-fase-1{
    background-color: #009fe3;
}

.linea-fase-1v2{
    background-color: white;
}

.download-linea-1{
    padding: 3%;
}

.row-linea-1v2{
    margin-top: 7%;
}

.fase-2-title-linea{
    color: white;
    font-size: 1.7em;
    font-weight: bolder;
    padding: 1%;
    text-align: center;
}

.body-menu-linea-1{
    color: white;
    font-size: 90%;
    padding: 1%;
    text-align: center;
}

.icon-consul-linea{
    width: 7% !important;
}


@media (max-width: 900px){
    .envi-menu-2 .corouse-linea-1{
        height: 290px;
        object-fit: cover;
    }

    .icon-consul-linea{
        width: 30% !important;
    }

    .fase-title-linea{
        color: white;
        font-size: 2.2em;
        font-weight: bolder;
        padding: 1%;
        text-align: center;
    }
}


/*NUEVO CSS AMBIENTAL*/

.enviromental-website-home{
    background-color: white;
}

/* Carousel inicial cabecera */

.carousel-enviromental .carousel-control-prev{
    display: none !important;
}

.carousel-enviromental .carousel-control-next-icon{
    display: none !important;
}

.btn-carousel-enviromental .carousel-control-next{
    display: none !important;
}

.carousel-enviromental .carousel-indicators{
    display: none !important;
}


.carousel-enviromental .btn-carousel-enviromental:hover{
    background-color: #af8732;
    transition: 0.5s;
}


@media (min-width:900px){
    .carousel-enviromental .carousel-item {
        height: 200px !important;
    }

    .carousel-enviromental .cabecera-txt-pr1{
        font-size: 300%;
        text-align: left;
        margin-bottom: -2% !important;
    }

    .carousel-enviromental .cabecera-txt-pr2{
        font-size: 450%;
        margin-bottom: -2% !important;
        text-align: left;
    }

    .carousel-enviromental .cabecera-txt-pr3{
        font-size: 550%;
        font-weight: bold;
        text-align: left;
        margin-bottom: 0% !important;
    }

    .carousel-enviromental p{
        margin-bottom: 0% !important;
    }

    .carousel-enviromental .carousel-caption{
        bottom: 23% !important;
    }

    .carousel-enviromental .cabecera-btn-pr4{
        text-align: left;
    }

    .carousel-enviromental .btn-carousel-enviromental{
        background-color: #ecb338;
        color: #6d5b1c;
        border-color: #ecb338;
        padding: 18px 26px;
        font-weight: bold;
    }
}

@media (max-width:900px){

    .carousel-enviromental .w-100 {
        width: 200% !important;
    }

    .carousel-enviromental .cabecera-txt-pr1{
        text-align: left;
        margin-bottom: -1% !important;
    }

    .carousel-enviromental .cabecera-txt-pr2{
        margin-bottom: -2% !important;
        text-align: left;
    }

    .carousel-enviromental .cabecera-txt-pr3{
        text-align: left;
        margin-bottom: 0% !important;
    }

    .carousel-enviromental .w-100 {
        width: 200% !important;
    }

    .carousel-enviromental .cabecera-txt-pr1 p{
        font-size: 100% !important;
    }

    .carousel-enviromental .cabecera-txt-pr2 p{
        font-size: 150% !important;
    }

    .carousel-enviromental .cabecera-txt-pr3 p{
        font-size: 200% !important;
        font-weight: bold;
    }

    .carousel-enviromental p{
        margin-bottom: 0% !important;
    }

    .carousel-enviromental .carousel-caption{
        bottom: 22% !important;
    }

    .carousel-enviromental .cabecera-btn-pr4{
        text-align: left;
    }

    .carousel-enviromental .btn-carousel-enviromental{
        background-color: #ecb338;
        color: #6d5b1c;
        border-color: #ecb338;
        padding: 13px 16px;
        margin-top: 3%;
        font-weight: bold;
    }
}

/* fin carousel inicial cabecera */

/* Sección contenidos */

.text-decor {
    border-bottom: 2px solid #60912a;
    width: 83px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
}

.info-enviromental{
   /* background-image: url(https://recycle.orionthemes.com/demo-1/wp-content/uploads/2016/12/cool-background.jpg); 
    opacity: .9;*/
    background-color: #F2F4F4;
    padding-bottom: 3%;
}

.info-enviromental .btn{
    border-radius: 0px !important;
}

.info-enviromental .card{
    border: 0px !important;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

@media  (max-width: 900px){

    .info-enviromental .card-deck .card {
        width: 88% !important;
        background-color: white !important;
        border: 0 !important;
    }

    .info-enviromental .card-body{
        background-color: white !important;
    }

    .info-enviromental-title p{
        font-size: 1.4rem;
    }

    .info-enviromental-title{
        padding-top: 3%;
        text-align: center;
        font-weight: bold;
        margin-top: 3%;
    }
}

@media (min-width:900px){

    .info-enviromental-title{
        padding-top: 3%;
        text-align: center;
        font-weight: bold;
        font-size: 212%;
    }

    .info-enviromental .card-deck{
        padding: 2% !important;
        margin-left: 9%;
        margin-right: 9%;
    }

    .info-enviromental .card-img-top {
        height: 50%;
    }

    .info-enviromental .card-title {
        margin-bottom: 1rem;
    }

}


/* fin seccion contenidos */

/* Seccion download */

.download-enviromental{
    background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/06/2023_6_2_8_22_cool-background.jpg); 
}

.download-enviromental-title{
    color: white ;
    padding-top: 3%;
    text-align: center;
    font-weight: bold;
    font-size: 212%;
}

.download-enviromental .card{
    border: 0px !important;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    margin-top: 1%;
    margin-bottom: 3%;
    text-align: center;
}

@media (max-width:900px){
    .download-enviromental p {
        font-size: 1.5rem;
    }

    .download-enviromental .card-deck .card {
        width: 88% !important;
        background-color: white !important;
        border: 0 !important;
    }

    .message-ico-png{
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 10%;
        margin-bottom: 3%;
    }
}

@media (min-width:900px){

    .download-enviromental .card{
        border: 0px !important;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        margin-left: 20%;
        margin-right: 20%;
    }

    .message-ico-png{
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 5%;
        margin-bottom: 1%;
    }
}



/*  Fin seccion download  */

/*  SECCION INCIDENCIAS */

.incident-enviromental{
    background-color: #F2F4F4;
}

.container-incident-enviromanetal{
    margin-bottom: 5%;

}

.incident-enviromental .info-enviromental-title{
    text-align: left !important;
}

.incident-enviromental .qr-app-store{
    width: 100% !important;
}

.incident-enviromental .qr-google-play{
    width: 100% !important;
}

.row-incident{
    text-align: center;
    margin-top: 10%;
}

@media(max-width:900px){

    .row-incident .qr-app-store{
        display: none;
    }

    .row-incident .qr-google-play{
        display: none;
    }

    .download-enviromental-stores .info-enviromental-title{
        text-align: center !important;
    }
    
}

@media (min-width: 900px){
    .container-incident-enviromanetal{
        margin-left: 20%;
        margin-right: 20%;
    }

    .incident-txt{
        padding-right: 20%;
        padding-bottom: 7%;
    }

    .download-enviromental-stores{
        display: none;
    }

}


/* FIN SECCION INCIDENCIAS */


/* FIN NUEVO CSS */