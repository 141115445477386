.infoBoxTorrelavega {
    background-color: #f2f4f4;
    text-align: center;
    padding-bottom: 4%;

    .infoTit {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 212%;
        padding: 3% 0% 1% 0%;
    }
    
    h4 {
        color: black;
        text-align: center;
        padding: 3% 0% 1% 0%;
    }
    .card {
        border: 0px !important;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        margin: 0% 6%;
        margin-top: 0% !important;
        border: 0px solid rgba(0, 0, 0, 0) !important;
        margin-bottom: 3%;
    }

    .card-header {
        background-color: #dbdbdb;
        color: white;
        text-align: center;
    }

    .card-body {
        padding: 1.25rem;
    }

    .card-text {
        text-align: left;
        color: white;
        padding: 0% 3%;
    }

    @media (max-width: 1000px) {
        .card-text {
            text-align: left;
            color: white;
            height: 100%;
        }
    }

    a {
        color: white;
        text-decoration: none !important;
    }

    a:hover {
        color: rgb(204, 202, 202);
        text-decoration: none;
        transition: all .3s;
    }

   
    p {
        padding: 0px;
        color: black;
    }

    .row {
        margin-bottom: 0% !important;
    }    

    .btn-outline-dark {
        color: white;
        border-color: white;
        margin: 3%;
    }

    .btn-primary {
        color: #fff;
        background-color: rgb(26, 141, 143);
        border-color: rgb(23, 99, 100);
    }

    .btn-primary:hover,
    .GamesBox .btn-primary:focus {
        background-color: rgb(23, 99, 100);
        border-color: rgb(23, 99, 100);
    }

    h5 {
        text-align: center;
    }

    * {
        font-family: 'Montserrat', sans-serif;
    }

    .par-hov:hover {
        color: #262626;
        transition: 1s;
        cursor: pointer;
    }

    .par-hov {
        color: black !important;
        font-weight: bold;
    }

    .par {
        color: black !important;
        font-weight: 500;
    }


    /******* new css ********/

    .first-env-box {
        background-color: white;
    }

    .content-section-1 {
        background-color: white;
    }

    @media(min-width: 900px) {
        .col-1-cs1 {
            margin-left: 30%;
        }
    }

    .local-enviromental-title {
        text-align: center !important;
        font-weight: bold;
        color: #262626 !important;
        font-size: 130%;
    }

    .par-hov:hover {
        color: #60912a !important;
        transition: 0.2s;
    }


    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2360912a' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2360912a' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }
    .carousel-indicators li {
        background-color: #60912a;
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
        bottom: -2.5em;
    }

    .carousel-control-next {
        right: -6em;
    }

    .carousel-control-prev {
        left: -6em;
    }
}