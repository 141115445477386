.inicio-sesion a {
  color: #198d8f;
  text-decoration: none;
  background-color: transparent;
}

.inicio-sesion a:hover, .inicio-sesion a:focus {
  color: hsl(181, 68%, 48%);
  transition: 0.6s;
  text-decoration: none;
  background-color: transparent;
}

.inicio-sesion a {
  color: white !important;
}
.inicio-sesion a:hover {
  color: rgb(179, 172, 172) !important;
}

.containerUser .center {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem !important;
}

.containerUser .center a{  
  color: white;
}
.legal-advice-container a {
  color: #a8feff;
}

.containerUser .search-container {
  margin: 0;
}

.mobile-holder {
  display: contents;
}


.containerPerfil.register-page .input-group-prepend{
  min-width: 200px;
}

/*.containerPerfil .register-page {
  width: 96%;
  width: 45rem;
}*/

.legal-advice-container {
  display: inline-flex;
}

.containerPerfilViladecans .card {
  background-color: #262626 !important;
}

.containerPerfilViladecans .input-group-text {
  background-color: #ed1558 !important;
}

.containerPerfilViladecans .form-check{
  font-weight: bold;
}

.containerPerfilViladecans .omb_authTitle{
  color: white !important;
  font-weight: bold;
}

.containerPerfilViladecans .form-check-label{
  color: white !important;
}

.containerPerfilViladecans .btn-primary{
  background-color: #ed1558;
}

.containerPerfilViladecans .input-group-text{
  color: white !important;
  font-weight: bold ;
}

.newsletter{
  color: white !important;
}


@media only screen and (min-width: 880px) {
  .containerPerfil.register-page{
    width: 800px;
  }

  .containerPerfil.register-page .intl-tel-input {
    min-width: 558px;
  }
}

@media only screen and (max-width: 880px) and  (min-width: 550px){
  .containerPerfil.register-page{
    width: 500px;
  }
}

  @media only screen and (max-width: 550px) {
    .containerPerfil.register-page{
      width: 100%;
    }
  
}