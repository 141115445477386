


.GamesBoxLn .card{
    background-color:#dcdcdc;
    color: black !important;
    width: 21rem !important;
    margin-top: 5% !important;
    border:0px solid rgba(0,0,0,.125);
}

.GamesBoxLn img{
    width: 100%;
    height: 107%;
}

.GamesBoxLn{
    align-content: center;
    color: black !important;
    text-align: center;
    padding-top: 5%;
    
}

.GamesBoxLn .btn-primary {
    background-color: #dcdcdc;
    border-color:#262626;
}

.GamesBoxLn .btn-primary:hover, .GamesBoxLn .btn-primary:focus {
    background-color:#016f9e;
    border-color: #262626;
}

.GamesBoxLn .card-title {
    text-align: center;
}

.GamesBoxLn .card-deck {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GamesBoxLn .card {
    margin-bottom: 15px;
    
}

.GamesBoxLn .btn-primary {
    background-color: black;
}

.GamesBoxLn .games-title-ln{
    color: white !important;
    padding-top: 2%;
}