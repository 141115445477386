.info-marker .carousel-inner{      
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.info-marker img {
    height: 160px;
    object-fit: cover;
}

.info-marker-poi{
    border: none;
    background: none;
    
}
.image-marker{
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 5px;
}

.title-marker{
    font-size: 16px;
    padding: 10px;
    text-align: left;
    font-weight: 500;
    cursor: pointer;
 }

 .navigation-marker-btn{
    width: 100%;
    font-size: 1em;
 }


 .info-marker .poi-links {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
}

.info-marker .poi-links svg {
    margin-right: 0px;
}

 .info-marker .poi-links a {
    margin-right: 5px;
}

.info-marker .poi-links .comprar-poi-btn {
    color: #d62828;
    background-color: transparent;
    border-color: #d62828;
    font-weight: 600;
    
}

.info-marker .poi-links .comprar-poi-btn:hover {
    color: #fff;
    background-color: #d62828;
}

.info-marker .poi-links .navigation-poi-btn {
    color: #003049;
    background-color: transparent;
    border-color: #003049;
    font-weight: 600;
}

.info-marker .poi-links .navigation-poi-btn:hover {
    color: #fff;
    background-color: #003049;
}