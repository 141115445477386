/******************** GLOBAL ************************/
#new-incident-card {
    padding: 10px 0px 0px 0px;
    font-size: 2.4em;
    color: #e8e8e8;
    margin-bottom: 10px;
}
#new-incident-card .row {
    border: dashed 3px #e8e8e8;
    background-color: rgba(0, 0, 0, 0.356);
    border-radius: 5px;
    transition: top 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: -moz-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    position: relative; 
    top: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    margin: 0 !important;
    line-height: normal;
}
#new-incident-card .row:hover {
    position: relative;
    top: -2px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
}
#new-incident-card div[class^="col"] {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#new-incident-card div[class^="space"] {
    background-color: #ffffff59;
    border-radius: 5px;
}
#new-incident-card .space1 {
    width: 60px;
    height: 60px;;
}
#new-incident-card .space2 {
    width: 120px;
    height: 30px;;
}
#new-incident-card .space3 {
    width: 180px;
    height: 30px;;
}
#new-incident-card .space4 {
    width: 20px;
    height: 30px;;
}
#new-incident-card .space5 {
    width: 30px;
    height: 30px;;
}
#new-incident-card .space6 {
    width: 40px;
    height: 40px;;
}
/******************** GLOBAL ************************/