/******************** GLOBAL ************************/
#survey-card {
    padding: 5px 0px 5px 0px;
    margin: 0.5em;
}
#survey-card .row {
    border: solid 1px #e8e8e8;
    border-radius: 5px;
    background-color: white;
    transition: top 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: -moz-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    position: relative; 
    top: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    margin: 0 !important;
}
#survey-card .row:hover {
    position: relative;
    top: -2px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
}
#survey-card .choosed {
    position: relative;
    top: -2px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
}
#survey-card div[class^="col"] {
    border-radius: 5px;
    padding: 0;
}

#survey-card .survey {
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 480px) {
    #survey-card .choosed::after {
        content: '';
        position: absolute;
        left: 100.3%;
        top: 42%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 13px;
        border-color: transparent transparent transparent #fffffffc;
        border-radius: 10px;
        
        clear: both;
    }
}

#survey-page .card {
    height: 50rem;
    border: 0px;
}

/******************** GLOBAL ************************/

/******************** IMAGE ************************/
/*#survey-card .image {
    max-width: 180px;
    padding: 5px;
    display: flex;
    flex-direction: row;
}*/
/*#survey-card img, .no-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
}*/
#survey-card .no-img {
    background-color: lightgrey;
}
/*@media only screen and (max-width: 480px) {
    #survey-card .image-after {
        display: none;
    }
}*/

#survey-card .card-img-top{ 
    height: 20rem;
    object-fit: cover;
}

/******************** IMAGES ************************/

/******************** INFO ************************/
#survey-card .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1px !important;
    width: 90%;
}
#survey-card .info p {
    margin-bottom: 0px;
}
#survey-card .info-title {
    font-size: 16px;
    font-weight: bold;
}
#survey-card .info-desc {
    font-size: 13px;
    color: #817d7d;
    font-weight: 500;
    overflow: hidden;
    width: 100%;
}
@media only screen and (max-width: 480px) {
    #survey-card .info {
        width: 217px;
    }
}
/******************** INFO ************************/

/******************** DATES ************************/
#survey-card .dates {
    display: flex;
    flex-direction: column;
    width: 266px;
    justify-content: center;
}
#survey-card .dates p {
    margin: 0;
}
@media only screen and (max-width: 480px) {
    #survey-card .dates {
        display: none;
    }
}
/******************** DATES ************************/

/******************** ACTIONS ************************/
#survey-card .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
#survey-card .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/******************** ACTIONS ************************/

/******************** STATUS ************************/
@media only screen and (max-width: 480px) {
    #survey-card .status {
        display: none;
    }
}
/******************** STATUS ************************/

.survey-scard:hover{
    cursor: pointer;
    background-color: rgb(202, 233, 238) ;
    box-shadow: black;
    transition: 1s;
}