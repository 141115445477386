.box-contact {
    padding-top: 5em !important;
    padding-bottom: 5em !important;
}

.box-content-torrelavega {
    background-color: white;
}

.img-email {
    width: 24%;
    margin-top: -2em;
}

.img-email2 {
    text-align: center;
}

.box-content-torrelavega .primer-texto {
    color: #286b3d;
    padding-top: 3%;
    text-align: center;
}

.box-content-torrelavega .primer-texto h2 {
    color: #286b3d;
}

.box-content-torrelavega .primer-texto p {
    font-size: 1.4em;
}

.box-content-torrelavega textarea,
.box-content-torrelavega input {
    background-color: #dff9e7;
}

@media (min-width: 810px) {
    .box-content-torrelavega iframe {
        width: 100%;
        height: auto;
    }
}

.row-box {
    padding-bottom: 1em;
    margin-left: 7%;
    margin-right: 7%;
}

.row-box .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #484848;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.25);
}

.box-content-torrelavega .color-heLeido {
    color: black;
}

.div-cajas {
    min-height: 4em;
    height: auto;
    width: auto;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
}

.div-cajas2 {
    height: 4em;
}

.box-mensaje {
    min-height: 9.5em;
    height: auto;
    width: auto;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
}

.form-control.box-mensaje2 {
    height: 9.5em !important;
}

.textarea.form-control.box-mensaje2 {
    height: 9.5em !important;
}

.box-content-torrelavega .buttom-hazTu.btn-primary {
    color: #28a745;
    border-color: #28a745;
    background-color: white;
}

.buttom-hazTu.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    transition: 0.5s;
    color: #fff;
    background-color: #615f5f;
    border-color: #615f5f;
}

.box-content-torrelavega .buttom-hazTu.btn-primary.focus,
.box-content-torrelavega .btn-primary:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.bottonEnv {
    width: 9em;
    padding: 0.6em;
}

.box-content-torrelavega .aviso-legal {
    color: black;
}


.box-content-torrelavega .aviso-legal:hover {
    text-decoration: underline !important;
    color: #286b3d;
    transition: 0.5s;
    font-size: 1.1rem !important;
}

.invalid-feedback {
    background-color: transparent;
}

.modal-title {
    color: #01b1b2;
}

.modal-body p {
    color: #615f5f;
}

.modal-footer .btn-secondary {
    background-color: #01b1b2 !important;
    border-color: #01b1b2;
}

.modal-footer .btn-secondary:hover {
    background-color: #049c9c !important;
    border-color: #01b1b2;
}


.card-contact-tv {
    margin-top: 50px;
}


.card-contact-tv .card-header {
    background-color: white !important;
    color: black;
    text-align: center;
    border-color: white;
}

.card-contact-tv .card-body {
    background-color: white !important;
    color: black;
    border-color: white;
}