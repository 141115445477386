#difusionPage .content {
    margin-top: 3em !important;
    color: #343738 !important;
}

#difusionPage .infoNoticia>div {
    padding: initial;
}


#difusionPage .infoNoticia {
    padding-bottom: 1em;
}

#difusionPage h2 {
    color: #343738;
    font-weight: bold;
    font-size: 2.05rem;
}

#difusionPage h3 {
    color: #343738;
}

#difusionPage .cuerpoNoticia {
    color: #000;
    text-align: justify;
}

.pointer {
    cursor: pointer;
}

link {
    text-decoration: underline;
}