/******************** PROGRESS BAR ************************/
#incident-progress {
    height: 100%;
}
#incident-progress .progressbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;
}
#incident-progress .progressbar .progressbar-item {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 70px;
}
#incident-progress .progressbar .progressbar-item div {
    height: 25px;
    width: 25px;
    border: solid 2px #7d7d7d;
    align-self: center;
    border-radius: 50%;
    text-align: center;
    padding: 1px;
    font-size: 13px;
    font-weight: 500;
    color: #817d7d;
    position: relative;
}
#incident-progress .progressbar .progressbar-item .active {
    color: white;
    background-color: #817d7d;
}
#incident-progress .progressbar .progressbar-item span {
    font-size: 13px;
    font-weight: 500;
    color: #817d7d;
}
#incident-progress .progressbar .progressbar-item .item:after {
    content: " ";
    display: block;
    position: absolute;
    height: 2px;
    background: grey;
    width: 47px;
    left: 23px;
    top: calc(40%);
}
/******************** PROGRESS BAR ************************/