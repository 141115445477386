.containerFind {
    padding: 10%;
    text-align: center;
}

.line5{
    background-color: black;
}

.red-text {
    color: red !important;
}

.sucess-text {
    color: green !important;
}