.practBoxTv {

    background-color: #f2f4f4;
    padding-bottom: 4%;
    
    .infoTit {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 212%;
        padding: 3% 0% 1% 0%;
    }

    .margin-bottom {
        padding-bottom: 4%;
    }

    .line {
        border-color: white !important;
    }


    @media (max-width: 1000px) {
        .card-text {
            text-align: left;
            color: white;
            height: 100%;
        }
    }

    a {
        color: white;
        text-decoration: none !important;
    }

    a:hover {
        color: rgb(204, 202, 202);
        text-decoration: none;
        transition: all .3s;
    }

    .card-body {
        padding: 1.25rem;
    }


    .row {
        margin-bottom: 0% !important;
    }

    

    img {
        width: 42% !important;
        height: auto;
        display: block;
        margin: auto;
    }

    .btn-primary {
        color: #28a745;
        border-color: #28a745;
        background-color: white;
    }

    .btn-primary:hover,
    .GamesBox .btn-primary:focus {
            color: white;
            border-color: #28a745;
            background-color: #28a745;
    }

    .card {
        border: 0px !important;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        margin: 2% 6%;
    }

    .card-header {
        background-color: #dbdbdb;
        color: black;
        text-align: center;
    }    
    .card-text {
        text-align: left;
        color: black;
        padding: 0% 3%;
    }

    .enviTit {
        padding: 5%;
    }

    h5 {
        text-align: center;
    }

    .citimg {
        width: 40%;
    }
}