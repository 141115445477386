.envInfTv {

    color: black !important;
    text-align: center;

    .infoTit {
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 212%;
        padding: 3% 0% 1% 0%;
    }

    .card {
        border: 0px !important;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        margin: 0% 6%;
        margin-top: 0% !important;
        border: 0px solid rgba(0, 0, 0, 0) !important;
        margin-bottom: 3%;
    }

    .card-header {
        background-color: white;
        color: black;
        text-align: center;
    }

    .card-body {
        padding: 1.25rem;
    }

    .card-text {
        text-align: left;
        color: black;
        padding: 0% 3%;
    }

    .line {
        border-color: #60912a !important;
    }

    a {
        color: #60912a !important;        
        text-decoration: none !important;
    }

    a:hover {
        color:#478502 !important;
        text-decoration: none;
        transition: all .3s;
    }

    .row {
        margin-bottom: 0% !important;
    }

    img {
        width: 30% !important;
        height: auto;
        display: block;
        margin: auto;
    }

    h5 {
        text-align: center;
    }

    .margin-bottom {
        padding-bottom: 4%;
    }
}