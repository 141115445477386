.login-page .search-container {
    max-width: 90vh;
    margin-left: auto !important;
    margin-right: auto !important;
    min-width: 50vw;
}
.search-container .input-group-prepend{
    width: 50px;
 }

.search-container .input-group-text {
    background-color: rgb(26, 141, 143);
    color: black;
}

.search-container .btn-primary { 
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: rgb(26, 141, 143);
}

.search-container .btn-primary:hover, .search-container .btn-primary:focus { 
    color: #fff;
    background-color: rgb(24, 121, 122);
    border-color: rgb(26, 141, 143);
}

.FormControl {
    color: white !important;
}

.loginBox .input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
    background-color: white !important;
    /*color: white !important;*/
}

.card>.list-group:first-child .list-group-item:hover {
    background-color: rgb(221, 221, 221);
}

.concejalia-name h2, .search-container span {
    color: #262626 !important;
}