.medioambiente-valverde{
    margin-top: 4%;
}

.valv-title{
    text-align: center;
    color: #262626;
    font-weight: bold;
}

.collapse-rojales .nav-link.active{
    background-color: #0c85d6;
    color: white !important;
}

.collapse-rojales .nav-pills .nav-link{
    color: #262626 ;
    font-weight: bold;
}

.img-contenedor-rojales{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    width: 60%;
}

.img-ecomobil-rojales{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    width: 80%;
}

.img-museo-rojales{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    width: 60%;
}

.img-parque-rojales{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    width: 60%;
}

.img-canino-rojales{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    width: 60%;
}