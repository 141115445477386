.section-info-ambiental-valverde,
.section-consultas {
    background-color: #6b8534;
}

.section-info-ambiental-valverde .card{
    margin: 2%;
}

.section-info-ambiental-valverde{

}

.title-valverde-2{
    text-align: center;
    color: white;
    font-weight: bold;
    padding-top: 2%;
}

.section-info-ambiental-valverde .container{
    margin-top: 0rem !important;
}