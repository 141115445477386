/******************** GLOBAL ************************/
#poi-detail {
    padding: 1em;
    background-color: white;
    border-radius: 5px;
    margin-top: 5px;
}
/******************** GLOBAL ************************/

/******************** HEADER ************************/
#poi-detail .header {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;    
    margin-top: 1em;    
}

#poi-detail .header-title {
    font-size: 1.6em;
    flex-grow: 2;
    font-weight: 600;
    padding-left: 0.3%;
    padding-right: 0.3%;
}

#poi-detail .audio-btn {
    float: right;
}

/******************** HEADER ************************/

/******************** INFO ************************/
#poi-detail .info .info-title {
    font-size: 1.3em;
    font-weight: 600;
}

#poi-detail .enlace-texto {
    color:#007bff;
}

#poi-card  .enlace-texto:hover{ 
    cursor: pointer;
}
/******************** INFO ************************/

/******************** ACTIONS ************************/
#poi-detail .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
#poi-detail .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#poi-detail .comprar-poi-btn {
    margin: 1em;
    margin-left: 0;
}

#poi-detail .audio-btn {   
    border: none;
    background: none;
    font-size: 1.8em;
}

/******************** ACTIONS ************************/

/******************** MAP ************************/
#poi-detail .map {
    margin-bottom: 1em;
}
#poi-detail .map img {
    width: 100%;
    border-radius: 5px;
}
#poi-detail .map-title {
    padding-top: 1em;
    padding-bottom: 1em;
}


/******************** MAP ************************/

/******************** SHARE ************************/
#poi-detail .share {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#poi-detail .share .share-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 3em;
}
#poi-detail .share div {
    padding-left: 0.2em;
    padding-right: 0.2em;
}
#poi-detail .facebook {
    color: #3b5998;
    cursor: pointer;
}
#poi-detail .twitter {
    color: #00acee;
    cursor: pointer;
}
#poi-detail .whatsapp {
    color: #25D366;
    cursor: pointer;
}
/******************** SHARE ************************/

/******************** IMAGES ************************/
#poi-detail .images{
    margin-bottom: 1em;
}
#poi-detail .images img{
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
#poi-detail .images {
    width: 100%;
    padding: 5px;
    /* display: flex; */
    flex-direction: row;
}
#poi-detail .images img, #poi-detail .no-img {
    width: 50%;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
    min-height: 150px;
    max-height: 300px;
    height: auto;
}
#poi-detail .no-img {
    background-color: lightgrey;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
/******************** IMAGES ************************/

#poi-detail .row-url{
    display: flex;
    flex-direction: row;
    font-size: 2em;
    padding: 0;
    margin-right: 5px;
}


#poi-detail .col{
    flex-grow: 0;
    padding-left:0;
}


/******************** HEAD **************************/

.row-hed-inc{
    margin-top: 2%;
    width: 100%;
}

.header-title{
    text-align: center;
    font-weight: bold;
}

.back-inc-btn {
    background-color: #41afb6;
    border: 0;
    margin-top: 6px;
}

.back-inc-btn:hover{
    background-color:#17beca;
}

/* @media (min-width: 1200px){

    .back-inc-btn{
        display: none;
    } 

}*/

.row-hed-inc svg{
    margin-right: 0px !important;
}

.card-poi-commet{
    text-align: left;
}

