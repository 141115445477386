


.GamesBoxPr .card{
    background-color:#8B1E3E;
    color: white;
    width: 21rem !important;
    margin-top: 5% !important;
    border:0px solid rgba(0,0,0,.125);
}

.GamesBoxPr img{
    width: 100%;
    height: 107%;
}

.GamesBoxPr{
    align-content: center;
    color: #262626;
    text-align: center;
    padding-top: 5%;
    
}

.GamesBoxPr .btn-primary {
    background-color: #4d0b1f;
    border-color:#a50050;
}

.GamesBoxPr .btn-primary:hover, .GamesBoxPr .btn-primary:focus {
    background-color:#8B1E3E;
    border-color: #a50050;
}

.GamesBoxPr .card-title {
    text-align: center;
}

.GamesBoxPr .card-deck {
    display: flex;
    justify-content: center;
    align-items: center;
}

.GamesBoxPr .card {
    margin-bottom: 15px;
    
}