/******************** GLOBAL ************************/
#incident-detail {
    padding: 1em;
    background-color: white;
    border-radius: 5px;
}
/******************** GLOBAL ************************/

/******************** HEADER ************************/
#incident-detail .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
}

#incident-detail .header-title {
    font-size: 0.7em;
    flex-grow: 2;
    margin-top: 3%;
    font-weight: 600;
}

/******************** HEADER ************************/

/******************** INFO ************************/
#incident-detail .info .info-title {
    font-size: 16px;
    font-weight: 600;
}

#incident-detail .info .info-desc {
    font-size: 14px;
    font-weight: 600;
}
/******************** INFO ************************/

/******************** ACTIONS ************************/
#incident-detail .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
#incident-detail .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#incident-detail .actions .svg-inline--fa{
    height: 16px;
}


#incident-detail .actions div:hover {   
    border: solid 2px  #0fc6b5;
    color:  #0fc6b5;
    cursor: pointer;
}
/******************** ACTIONS ************************/

/******************** MAP ************************/
#incident-detail .map {
    margin-bottom: 1em;
}
#incident-detail .map img {
    width: 100%;
    border-radius: 5px;
}
#incident-detail .map-title {
    padding-top: 1em;
    padding-bottom: 1em;
}
/******************** MAP ************************/

/******************** SHARE ************************/
#incident-detail .share {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#incident-detail .share .share-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 3em;
}
#incident-detail .share div {
    padding-left: 0.2em;
    padding-right: 0.2em;
}
#incident-detail .facebook {
    color: #3b5998;
    cursor: pointer;
}
#incident-detail .twitter {
    color: #00acee;
    cursor: pointer;
}
#incident-detail .whatsapp {
    color: #25D366;
    cursor: pointer;
}
/******************** SHARE ************************/

/******************** IMAGES ************************/
#incident-detail .images{
    margin-bottom: 1em;
}
#incident-detail .images img{
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
#incident-detail .images {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
}
#incident-detail .images img, #incident-detail .no-img {
    width: 50%;
    object-fit: cover;
    border-radius: 5px;
    min-height: 150px;
    max-height: 300px;
    height: auto;
}
#incident-detail .no-img {
    background-color: white;
}

#incident-detail img:before {
    /*Solo se muestra en imágenes rotas*/
    display: block;
    box-sizing: border-box;
    content: "" ;
    white-space: pre-wrap;
    position: absolute;
    color:#fff;
    text-align:center;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
    min-height: 150px;
    max-height: 150px;
    height: auto;
    background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/10/2023_10_31_12_2_no-photo.png);    
    background-color: #fff;    
    background-repeat: no-repeat;
    background-position: center;
}
/******************** IMAGES ************************/

/******************** HEAD **************************/

.row-hed-inc{
    margin-top: 2%;
    width: 100%;
    padding: 0px;
}

.header-title{
    text-align: center;
}

.back-inc-btn {
    background-color: #41afb6;
    border: 0;
    margin-top: 6px;
}


.back-inc-btn:hover{
    background-color:#17beca;
}

/* @media (min-width: 1200px){

    .back-inc-btn{
        display: none;
    } 

}*/

.row-hed-inc svg{
    margin-right: 0px !important;
}

.card-incident-commet{
    text-align: left;
}

