* {
    font-family: 'Montserrat', sans-serif;
}

.loginBox .card-header {
    text-align: center;
}

.loginBox .omb_row-sm-offset-3 div:first-child[class*="col-"] {
    margin-left: 0% !important;
}

.loginBox blockquote {
    border-left: rgb(26, 141, 143);
}

.hr-1 {
    border: none;
    border-left: 1px solid white;
    height: 100%;
    width: 1px;
    margin: auto;
}

.loginBox .btn-info {
    color: #fff;
    background-color: #28a69a;
    border-color: #28a69a;
    display: block;
    margin: auto;
    padding: .4rem 0.9rem;
    font-size: 1.15rem;
    line-height: 1.5;
    border-radius: .3rem;
}


.loginBox .btn-info:hover, .loginBox .btn-info:focus{
    background-color: #0a554d;
    border-color: #0a554d;
    transition: all .3s;
}


.recuperate a {
    color: #28a69a;
    font-size: 16px;
}

.recuperate a:hover {
    color: #12efda;
}

.img-title-logo {
    width: 60px;
    margin-right: 0.3em;
}

.span-title-logo {
    vertical-align: middle;
}

.recuperate .row {
    text-align: center;
    font-size: 83%;

    text-align: center;
    text-decoration: none !important;
}

.loginBox .card {
    margin-top: 0% !important;
    background-color: #313131;
}

.loginBox .card-header {
    background-color: #313131 !important;
    color: white;
}

.title-register {
    padding: 0.8em 0.8em 0.8em 0.8em;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 12px;
}

.title-login {
    padding: 0.8em 0em 0em 0em;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0%;
}

.recuperate p {
    margin-top: 10px;
    margin-bottom: 0%;
    text-align: center !important;
}

#login-page .container {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.loginBox .input-group {
    background-color: #aaa;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
}

.line7 {
    background-color: #aaa;
    margin-left: auto;
    margin-right: auto;    
}

.loginBox .form-control:focus {
    color: black !important;
}

.loginBox .form-control {
    color: black !important;
}

.loginBox .input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    color: black !important;
}

.loginBox .center {
    color: white;
    font-size: 60%;
}


.loginBox .w-100 {
    color: white;
    font-size: 60%;
    margin-bottom: 2rem;
}

.containerl {
    margin-top: 0px;
    padding: 5%;
}

.login-page {
    color: white;
}

.otherLog {
    text-align: center;
    height: 100%;
}

.recuperate span {
    font-size: 140%;
}

#login-page .center {
    color: white;
    font-size: 3rem;
}

#login-page .center a {
    color: grey;
    font-size: 1.2rem;
}

#login-page .center a:hover {
    color: #28a69a;
}

#login-page .search-container {
    margin: 0;
}

@media (max-width: 768px) {
    .otherLog .col {
        position: relative;
        /* padding-right: 15px;
        padding-left: 15px; */
    }
}

.changent:hover {
    cursor: pointer;
    color: #cdfffa;
}

#login-page .span-title-logo {
    color: white !important;
    font-size: 0.65em;
}

.data-tcotillas{
    color: white;
    font-size: 50%;
}

.user-politic-tcotilla{
    color: #14c0af !important;
    cursor: pointer;
    text-decoration: underline !important;
}

.hide-tcotillas{
    display: none !important;
}



@media (min-width: 1000px) {
.loginBox{
    width: 1000px;
}
}