/******************** GLOBAL ************************/
#incident-card {
    padding: 10px 0px 0px 0px;
}
#incident-card .row {
    border: solid 1px #e8e8e8;
    border-radius: 5px;
    background-color: white;
    transition: top 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: -moz-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    position: relative; 
    top: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    margin: 0 !important;
}
#incident-card .row:hover {
    position: relative;
    top: -2px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
}
#incident-card .choosed {
    position: relative;
    top: -2px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
}
#incident-card div[class^="col"] {
    border-radius: 5px;
    padding: 0;
}

@media only screen and (min-width: 900px) {
    #incident-card .incident {
        display: flex;
        justify-content: space-around;
    }
}


@media only screen and (max-width: 900px) {
    #incident-card .incident {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
    }
}

#incident-card .incident {
    display: flex;
    justify-content: space-between;
}
@media only screen and (min-width: 1200px) {
    #incident-card .choosed::after {
        content: '';
        position: absolute;
        left: 100.3%;
        top: 42%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 13px;
        border-color: transparent transparent transparent #fffffffc;
        border-radius: 10px;        
        clear: both;
    }
}
/******************** GLOBAL ************************/

/******************** IMAGES ************************/
#incident-card .images {
    max-width: 180px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    min-height: 90px;
}
#incident-card img, .no-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
}
#incident-card .no-img {
    background-color: white;
}
/*
@media only screen and (max-width: 480px) {
    #incident-card .image-after {
        display: none;
    }
}
*/

#incident-card img:before {
    /*Solo se muestra en imágenes rotas*/
    display: block;
    box-sizing: border-box;
    content: "" ;
    white-space: pre-wrap;
    position: absolute;
    color:#fff;
    text-align:center;
    height: 80px;
    width: 80px;
    object-fit: cover;
    background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/10/2023_10_31_12_2_no-photo.png);    
    background-color: #fff;
}
/******************** IMAGES ************************/

/******************** INFO ************************/
#incident-card .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1px !important;
    width: 275px;
}
#incident-card .info p {
    margin-bottom: 0px;
}
#incident-card .info-title {
    font-size: 16px;
    font-weight: bold;
}
#incident-card .info-desc {
    font-size: 13px;
    color: #817d7d;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
@media only screen and (max-width: 480px) {
    #incident-card .info {
        width: 217px;
    }
}
/******************** INFO ************************/

/******************** DATES ************************/
#incident-card .dates {
    display: flex;
    flex-direction: column;
    width: 266px;
    justify-content: center;
}
#incident-card .dates p {
    margin: 0;
}
@media only screen and (max-width: 480px) {
    #incident-card .dates {
        display: none;
    }
}
/******************** DATES ************************/

/******************** ACTIONS ************************/
#incident-card .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;   
    width: 10%;
}
#incident-card .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#incident-card .actions svg:not(:root).svg-inline--fa{
    margin-right: 0px !important;
}

#incident-card .actions div:hover {   
    border: solid 2px #0fc6b5;
    color: #0fc6b5;
    cursor: pointer;
}
/******************** ACTIONS ************************/

/******************** STATUS ************************/
/*
@media only screen and (max-width: 480px) {
    #incident-card .status {
        display: none;
    }
}
*/
/******************** STATUS ************************/

