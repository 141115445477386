
.line {
    border-color: white !important;
}

.enviBoxLn .card-text {
    text-align: left;
    color: black !important;

}

.enviBoxLn a {
    color: black !important;
    text-decoration: none !important;
}

.enviBoxLn a:hover {
    color:#262626;  text-decoration: none; transition: all .3s;
}

.enviBoxLn .card-body {
    background-color: #eeeeee;
}


.enviBoxLn .row{
    margin-bottom: 0% !important;
}

.enviBoxLn .card-header {
    background-color:#eeeeee;
    color: black !important;
    text-align: center;
}

.enviBoxLn img {
    width: 30% !important;
    height: auto;
    display:block;
    margin:auto;
}

.enviBoxLn .btn-primary {
    color: #fff;
    background-color: black;
    border-color: rgb(23, 99, 100);
}

.enviBoxLn .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.enviBoxLn .card {
    margin-top: 3% !important;
    border: 0px solid rgba(0,0,0,0) !important;
    margin-bottom: 5%;
}

.enviBoxLn {
    color: black !important;
    text-align: center;
    
}

.enviTit{
    padding: 4%;
}

.enviBoxLn h5 {
    text-align: center;
}

.margin-bottom {
    padding-bottom: 4%;
}

.black-txt-ln{
    color: black !important;
}

.environ .infoTit{
    padding-top: 10% !important;
}