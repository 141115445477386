/********************* SECTION VIDEO *********************/





/********************* SECTION VIDEO *********************/


/********************* SECTION FEATURES *********************/


#section-features {
    text-align: center;
    background-color: #025555;
    padding: 2rem 0rem 2rem 0rem;
}
#section-features .card-titulo {
    padding: 2rem 0 2rem 0;
    color: white !important;
}
#section-features p {
    color: white;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}
#section-features h4 {
    color: white !important;
}
#section-features svg {
    color: white;
    margin-bottom: 4%;
}
#section-features div[class^="col"] {
    padding: 2rem 2rem;
}
@media (min-width: 320px) and (max-width: 480px) {
    #section-features div[class^="col"] {
        padding: 1rem 1rem;
    }
}


/********************* SECTION FEATURES *********************/


/********************** SECTION MODULES EXTENDED *********************/
#section-modules-extended {
    background-color: white;
    padding: 2rem 0rem 2rem 0rem;
    text-align: center;
}
#section-modules-extended .module-text {
    text-align: left;
    padding-left: 10px;
}
#section-modules-extended .container-element {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#section-modules-extended ul {
    margin-bottom: 0 !important;
}
#section-modules-extended li {
    text-align: left;
}
#section-modules-extended img {
    object-fit: cover;
    width: 10rem;
    height: 10rem;    
    border-radius: 25px;
    padding: 5px;
}
#section-modules-extended p {
    color: #313131;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
}
#section-modules-extended .card-titulo {
    padding: 0rem 0 2rem 0;
    color: #01b1b2 !important;
}
#section-modules-extended .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#section-modules-extended .left-container {
    margin-left: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#section-modules-extended .row {
    margin-bottom: 0 !important;
}

@media (min-width: 320px) and (max-width: 768px) {
    .col-card-module{
        margin-bottom: 5%;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    #section-modules-extended p{
        padding-left: 3%;
    }
    #section-modules-extended .container-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 2em;
    }
    #section-modules-extended .left-container {
        margin-left: 0;
    }
    #section-modules-extended .card-titulo {
        padding: 2rem 0 0rem 0;
    }
    #section-modules-extended {
        padding: 0rem 0rem 2rem 0rem;
    }
    #section-modules-extended img {
        width: 5rem;
        height: 5rem;    
    }
}
/********************* SECTION MODULES EXTENDED *********************/

/********************* SECTION MODULES  *********************/

#section-modules {
    text-align: center;
    background-color: #025555;
    padding: 0rem 0rem 4rem 0rem;
}
#section-modules .card-titulo {
    padding: 2rem 0 2rem 0;
    color: white !important;
    font-size: 2rem;
}
#section-modules .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
}
#section-modules .card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 3%;
    background: #313131;
    position: relative;
    display: flex;
    align-items: center;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    color: #e7f7fa;
}
#section-modules .card:hover {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
}
#section-modules .card:hover:before {
    opacity: 1;
}
#section-modules .card:hover .info {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}
#section-modules .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}
#section-modules .card svg {
    font-size: 5rem;
    margin-bottom: 1rem;
}

#section-modules .card h2{
    font-size: 1rem !important;
}

#section-modules .card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    transition: 0.5s;
}
#section-modules .card .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
}
#section-modules .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
}


@media (max-width: 1200px) { 
    #section-modules .card {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4%;
    }
}


/********************* SECTION MODULES *********************/

/********************* SECTION SCHEMA *********************/
#section-schema {
    text-align: center;
    padding: 2rem 0rem 2rem 0rem;
}
#section-schema .card-titulo {
    padding: 0rem 0 2rem 0;
}
/********************* SECTION SCHEMA *********************/

/********************* SECTION SUCCESS CASES *********************/


#section-success-cases .carousel-indicators {
    position: relative;
}

#section-success-cases {
    text-align: center;
    padding: 2rem 0 2rem 0;
}
#section-success-cases .carousel-indicators li {
    background-color: #ccc !important;
}
#section-success-cases .carousel-indicators .active {
    background-color: #333 !important;
}
#section-success-cases .slider__caption {
    font-weight: 500;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    /*
    margin-bottom: -50px;
    z-index: 9;
    */
}
#section-success-cases .slider__txt {
    color: #636363;
    margin-bottom: 0rem;
    max-width: 300px;
}
#section-success-cases  .carousel-caption {
    top: 0px;
    bottom: auto;
    padding-bottom: 0;
}
/********************* SECTION SUCCESS CASES *********************/
@media (max-width: 993px){
    #section-modules-extended img {
        display: none;
    }
}


@media (max-width: 768px){
    .schema-city{
        display: none;
    }
    
    #section-modules-extended ul{
        list-style: square;
    }
    
}

@media (min-width: 768px){
    .schema-city-r{
        display: none;
    }
}





