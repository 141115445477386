#poi-search {
    padding-top: 1em;
    /*padding-left: 1em;
    padding-right: 1em;*/
    background-color: white;
    flex-direction: row;
    align-items: center;    
    text-align: center; 
}

#poi-search .header-title{
    font-size: 2em;
    flex-grow: 2;
    font-weight: 600;
    padding-left: 0.3%;
    padding-right: 0.3%;
}

#poi-search .active{
    font-weight: bold;
}

#poi-card a:hover{ 
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #poi-search .menu-poi{
        text-align: center;
        margin-bottom: 0.5em !important;   
    }
  }