

h4 {
    text-align: center;
}


.legal-box .card-text {
    text-align: left;
    color: white;
    height: 175px;
}

@media (max-width: 1000px){
    .legal-box .card-text {
        text-align: left;
        color: white;
        height: 100%;
    }
}


.legal-box .card-body {
    background-color: rgb(26, 141, 143);
}

.legal-box .card-header {
    background-color: rgb(24, 116, 117);
    color: white;
    text-align: center;
}

.legal-box .card {
    margin-top: 3% !important;
    border: 0px solid rgba(0,0,0,0) !important;
}

.legal-box {
    color: white;
    text-align: center;
}

.legal-box .card-text {
    height: auto !important;
}

.legal-box .card {
    margin-bottom: 10%;
}


.legal-box a {
    color: white !important;
}