.margin-bottom {
    padding-bottom: 4%;
}

.line {
    border-color: white !important;
}

.enviBox .card-text {
    text-align: left;
    color: white;
    height: 175px;
}

@media (max-width: 1000px){
    .enviBox .card-text {
        text-align: left;
        color: white;
        height: 100%;
    }
}


.enviBox a {
    color: white;
    text-decoration: none !important;
}

.enviBox a:hover {
    color: rgb(204, 202, 202);  text-decoration: none; transition: all .3s;
}

.enviBox .card-body {
    background-color: rgb(26, 141, 143);
}


.enviBox .row{
    margin-bottom: 0% !important;
}

.enviBox .card-header {
    background-color: rgb(24, 116, 117);
    color: white;
    text-align: center;
}

.enviBox img {
    width: 42% !important;
    height: auto;
    display:block;
    margin:auto;
}

.enviBox .btn-primary {
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
}

.enviBox .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.enviBox .card {
    margin-top: 3% !important;
    border: 0px solid rgba(0,0,0,0) !important;
}

.enviBox {
    color: white;
    text-align: center;
}

.enviTit{
    padding: 5%;
}

.enviBox h5 {
    text-align: center;
}

.citimg{
    width: 40%;
}