/********************* BANNER CERTIFICATE GLOBAL*********************/


#banner-certificate-global{
    height: auto;
    background-color:#313131;
}

#banner-certificate-global .copy-ban{
    margin:0;
    padding:0;
}

#banner-certificate-global .container {
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-content: center;
}

@media (max-width: 768px){
    #banner-certificate-global .container img{
        width: 65%;
    }

    .ens{
        width: 50% !important;
        padding: 2%;
    }
    
    
    .dti{
        width: 50% !important;
        padding: 2%;
    }

    .fiw{
        width: 60% !important; 
    }

}

#banner-certificate-global .container img{
    width: 36%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: none;
}

#banner-certificate-global .container div a{
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: none;
}


/********************* BANNER CERTIFICATE HOME*********************/

#banner-certificate {
    height: auto;
    padding-bottom: 1rem;
    background-color: #025555;
}
#banner-certificate .container {
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-content: center;
}

.BannerCertificateRow{
    padding-top: 0.9em;
    width: 100%;
    text-align: center;
}
#banner-certificate .container .Col{

    width: 100%;
    text-align: center;
}

#banner-certificate .container h2{
    color: white;
}

#banner-certificate .container img{
    width: 36%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: none;
}

#banner-certificate .container div a{
    color: white;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: none;
}


@media (min-width: 320px) and (max-width: 480px) {

}

.row{
    margin-left: 0;
    margin-right: 0;
}


@media (max-width: 768px){
    #banner-certificate .container img{
        width: 65%;
    }

    .ens{
        width: 50% !important;
        padding: 2%;
    }
    
    
    .dti{
        width: 50% !important;
        padding: 2%;
    }

    .fiw{
        width: 60% !important; 
    }

}

@media (min-width: 768px){

    .ens{
        width: 32% !important;
    }


    .dti{
        width: 80% !important;
        padding: 3%;
    }

    .fiw{
        width: 90% !important;
    }
}