.carousel-noticia-body {
    height: 175px !important;
}

.carousel-noticia-item {
    height: 100% !important;
}

.carousel-noticia-subtitle {
    height: 70px;
}

.carousel-noticia-footer {
    height: 40px;
}

.img-noticia-carousel {
    height: 250px !important;
}