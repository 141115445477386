.box-contact{
    padding-top: 5em !important;
    padding-bottom: 5em !important;

.box-content {
    background-color: rgba(15, 199, 181, 0.6);
}
.img-email {
    width: 24%;
    margin-top: -2em;
}
.img-email2 {
    text-align: center;
}
.primer-texto {
    color: white; 
    padding-top: 3%;
    text-align: center;
}
.primer-texto p {
    font-size: 1.4em;
}

.row-box {
    padding-bottom: 1em;
    margin-left: 7%;
    margin-right: 7%;
}
.row-box .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #484848;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.25);
}
.color-heLeido {
    color: white
}

.div-cajas {
    min-height: 4em;
    height: auto;
    width: auto;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
}
.div-cajas2 {
    height: 4em;
}
.box-mensaje {
    min-height: 9.5em;
    height: auto;
    width: auto;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
}
.form-control.box-mensaje2 {
    height: 9.5em !important;
}

.textarea.form-control.box-mensaje2 {
    height: 9.5em !important;
}
.buttom-hazTu.btn-primary{
    color: #fff;
    background-color: #484848;
    border-color:  #484848;
}

.buttom-hazTu.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    transition: 0.5s;
    color: #fff;
    background-color: #615f5f;
    border-color: #615f5f;
}

.buttom-hazTu.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}

.bottonEnv {
    width: 9em;
    padding: 0.6em;
}
.aviso-legal {
    text-decoration: underline  !important;
    color:white;
    font-size: 1rem !important;
}
.aviso-legal:hover {
    text-decoration: underline  !important;
    color:white;
    transition: 0.5s;
    font-size: 1.1rem !important;
}

.invalid-feedback { 
    background-color: transparent;
}  

.modal-title {
    color: #01b1b2;
}

.modal-body p{
    color:#615f5f;
}

.modal-footer .btn-secondary {
    background-color: #01b1b2 !important;
    border-color: #01b1b2;
}

.modal-footer .btn-secondary:hover {
    background-color: #049c9c !important;
    border-color: #01b1b2;
}


.titl-g-1{
    text-align: center;
    font-size: 2.3em;
    padding: 3%;
    text-decoration: underline;
    color: white;
}

.body-g-1 p{
    color: white;
    font-size: 1.3em;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 1%;
}

.actividades{
    padding-left:5%;
}

.info-adj h2{
    font-size: 2.3em;
    color: white;
    font-weight: bold;
    text-align: center;
}

.info-adj p{
    padding-top: 1%;
}

.info-adj{
    text-align: center;
}

.info-adj a{
    color: white;
    text-decoration: underline;
}

@media (min-width: 810px){
    iframe{
        width:80%;
        height: 350px;
    }
}

}