.infoBoxLn .card-text {
    text-align: left;
    color: white;
}

@media (max-width: 1000px){
    .infoBoxLn .card-text {
        text-align: left;
        color: white;
        height: 100%;
    }
}

.infoBoxLn a {
    color: white;
    text-decoration: none !important;
}

.infoBoxLn a:hover {
    color: rgb(204, 202, 202);  text-decoration: none; transition: all .3s;
}

.infoBoxLn .card-body {
    background-color:#dbdbdb;
}


.infoBoxLn .row{
    margin-bottom: 0% !important;
}

.infoBoxLn .card-header {
    background-color: #dbdbdb;
    color: white;
    text-align: center;
}

.infoBoxLn img {
    width: 60%;
    height: auto;
    display:block;
    margin:auto;
}

.infoBoxLn .btn-outline-dark {
    color: white;
    border-color: white;
    margin: 3%;
}

.infoBoxLn .btn-primary {
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
}

.infoBoxLn .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.infoBoxLn .card {
    margin-top: 0% !important;
    border: 0px solid rgba(0,0,0,0) !important;
    margin-bottom: 3%;
}

.infoBoxLn {
    color: white;
    text-align: center;
    padding-bottom: 4%;
}

.infoBoxLn .infoTit{
    padding: 8% !important;
}

.infoBoxLn h5 {
    text-align: center;
}


* {
    font-family: 'Montserrat', sans-serif;
}

.par-hov-ln:hover{
    color: #262626;
    transition: 1s;
    cursor: pointer;
}

.par-hov-ln{
    color: black !important;
    font-weight: bold;
}

.par-ln{
    color: black !important;
    font-weight: 500;
}


/******* new css ********/

.first-env-box{
    background-color: white;
}

.content-section-1{
    background-color: white;
}

@media(min-width: 900px){
    .col-1-cs1{
        margin-left: 30%;
    }
}

.infoBoxLn .local-enviromental-title{
    text-align: center !important;
    font-weight: bold;
    color: #262626 !important;
    font-size: 130%;
}

.par-hov-ln:hover{
    color: #60912a !important;
    transition: 0.2s;
}
