.search-tipology .tipology {
    font-size: 12px;
}

.search-tipology .tipology img {
    background-color: #08B6B9;
    height: 40px;
    border-radius: 150px;
    margin-right: 1em;
}
/*
.search-tipology {
    margin-right: 3em;
}

*/
.search-tipology-items {
    width: 100%;
    background-color: transparent;
    overflow: scroll;
    max-height: 80vh;
    z-index: 2;
    position: absolute;
}


.search-tipology input{
    font-size: 1rem;
}