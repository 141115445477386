.pointer {
    cursor: pointer;
}

.menu.navbar {
    background-color: #313131;
}

.nav-link{
    color:white;
}

.nav-link:hover{
    color:#01b1b2;
}

.whitemenu {
    background-color: white !important;
    color: #262626 !important;
}

.menu.navbar-light .navbar-nav .nav-link {
    color: white;
    font-size: 1em;
}

.whitemenu.navbar-light .navbar-nav .nav-link {
    color: #262626 !important;
    font-size: 1em;
}

.iconBrand {
    width: 9em;
}

.img-header {
    width: 100%;
}

.img-header_143 {
    width: 50% !important;
}

.img-header_268 {
    width: 10% !important;
}

.img-header_206 {
    max-height: 100px;
}

header .menu.navbar-light .navbar-nav .nav-link:hover {
    background-color: #575656 !important;
}

.buttomToggle.navbar-toggler {
    background-color: rgb(27, 150, 150) !important;
}

.accInfo {
    padding-left: 0 !important;
}

#buttonToggle {
    background-color: #f5f5f5 !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #313131;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #01b1b2;
    text-decoration: none;
    transition: all .3s;
}

#header .nuevo-aviso {
    /*background-color: rgb(15, 199, 181);*/
    background-color: #025555;
    color: white !important;
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
    padding-left: 20px;
    padding-right: 20px;
}

.menu.navbar-light .navbar-nav .nav-link:hover {
    color: #bae9e9;
    transition: all .3s;
}

#header .nuevo-aviso:hover,
#header .nuevo-aviso:focus {
    background-color: white;
    color: #01b1b2 !important;
    transition: all .3s;
}

@media (max-width: 1239px) {
    .menu.navbar-light .navbar-nav .nav-link {
        font-size: 0.7em;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .menu.navbar-light .navbar-nav .nav-link {
        font-size: 1em;
    }
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
    margin-right: 5px;
}

.call-ch {
    padding: 3%;
    text-align: center;
    color: white;
    font-size: 2em;
}

.img-Header_294e {
    width: 15%;
}

@media (max-width: 1156px) {
    .navbar-brand {
        width: 76%;
    }
}

@media (max-width: 768px) {
    .img-Header_294e {
        width: 29%;
    }

    .navbar-brand {
        width: 65%;
    }
}

.img-Header_430e {
    width: 20%;
    margin-left: 4%;
}

.iconBrand_430 {
    width: 50%;
}

.alco-men {
    background-color: #008f4c !important;
}

.alco-men .nav-title {
    display: none;
}

.alco-men .nav-link {
    color: white !important;
    font-size: 1em;
}

.alco-men .dropdown-menu {
    background-color: #008f4c !important;
}

.nav-link:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .alco-men .nav-title {
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
    }
}

@media (max-width: 520px) {
    .alco-men .concejalia-name {
        display: none;
    }

    .navbar {
        padding: 0.5rem 1rem;
    }

    .nav-link {
        display: block;
        padding: 0% !important; 
    }

    .img-header {
        width: 85%;
    }

    .img-header_143 {
        width: 23% !important;
    }
}


.concejalia-name h2, #header span {
    color: #FFFFFF !important;
}

.img-Header_180 {
    height: 85px;
}

.header_180 .nav-link {
    font-size: 1.10em !important;
}

/******** VILADECANS CSS *******/

@media (min-width: 991px) {
    .img-Header_429e{
        width: 21%;
    }
}

@media (max-width: 991px) {
    .img-Header_429e{
        width: 70%;
    }
}

#header .nuevo-aviso-viladecans {
    /* background-color: rgb(15, 199, 181); */
    background-color: #ed1558;
    color: white !important;
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
    padding-left: 20px;
    padding-right: 20px;
}

#header .nuevo-aviso-viladecans:hover {
    background-color: #ad1948;
    transition: 1s;
}

#header .menu-viladecans .buttomToggle.navbar-toggler{
    background-color: #ad1948 !important;
}

/******* ADEJE CSS ********/

@media (min-width: 991px) {
    .img-Header_10410e{
        width: 16%;
    }
}

@media (max-width: 991px) {
    .img-Header_10410e{
        width: 70%;
    }
}

#header .nuevo-aviso-adeje {
    /* background-color: rgb(15, 199, 181); */
    background-color: #7763b9;
    color: white !important;
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
    padding-left: 20px;
    padding-right: 20px;
}

#header .nuevo-aviso-adeje:hover {
    background-color: #9181c9;
    transition: 1s;
}

/***** VALVERDE ******/

.valv-men {
    background-color: #759236 !important;
}

.valv-men .nav-title {
    display: none;
}

.valv-men .nav-link {
    color: white !important;
    font-size: 1em;
}

.valv-men .dropdown-menu {
    background-color: #759236 !important;
}

.nav-link:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .valv-men .nav-title {
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
    }
}

@media (max-width: 520px) {
    .valv-men .concejalia-name {
        display: none;
    }
}



/***** ROJALES ******/

.rojales-men {
    background-color: #0c85d6 !important;
}

.rojales-men .nav-title {
    display: none;
}

.rojales-men .nav-link {
    color: white !important;
    font-size: 1em;
}

.rojales-men .dropdown-menu {
    background-color: #0c85d6 !important;
}

.nav-link:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .rojales-men .nav-title {
        display: flex;
        flex-direction: column;
        text-align: right;
        color: white;
    }
}

@media (max-width: 520px) {
    .valv-men .concejalia-name {
        display: none;
    }
}

/** MENU ENVIROMENTAL NEW JUNIOR 06-23 **/

.enviromental-nav{
    background-color: rgba(37, 53, 38, 0.85);
    color: white !important;
}

.enviromental-nav-container{
    color: white !important;
}

.enviromental-men{
    color: white !important;
}

.enviromental-men a{
    color: white !important;
}

.enviromental-nav-img-linea{
    width: 17%;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/06/2023_6_5_9_25_menus000.png);
}

.enviromental-nav-container .navbar-light .navbar-toggler{
    color: rgba(0,0,0,.5);
    border-color: white !important;
    border-radius: 0rem !important;
}

.enviromental-nav-container .navbar-light .navbar-toggler:hover{
    padding: 1%;
    transition: 0.5s;
}

.enviromental-men .result-container{
    color: #262626 !important;
}

@media (min-width:900px){

    .enviromental-nav-container{
        margin-left: 40%;
        margin-right: 40%;
        padding: 0% !important;
    }

}

@media (max-width:900px){
    .enviromental-nav-container{
        padding: 0% !important;
    }

    .enviromental-nav-logo{
        width: 75%;
    }

    .enviromental-nav-img-linea {
        width: 32%;
    }

    .enviromental-men{
        margin-top: 3%;
    }

    .enviromental-men .dropdown-menu {
        background-color: rgba(37, 53, 38, 0.85);
    }
}




/** END NEW ENVIROMENTAL MENU JUNIOR **/
