.rec-box-med-t{
    background-color: rgb(24, 116, 117);
    margin: 5%;
    color: white;
    font-size: 2em;
    cursor: pointer;
}

.rec-box-med-t:hover{
    background-color:rgb(37, 164, 167);
}

.rec-box-img{
    width: 30%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
    
}

.title-box-med{
    padding:4%;
    text-align: center;
    font-weight: bold;
}


.fase-title-torremolinos{
    color: white;
    font-size: 2.5em;
    font-weight: bolder;
    padding: 1%;
    text-align: center;
}

.torremolinos-fase-1{
    background-color: #009fe3;
}

.torremolinos-fase-1v2{
    background-color: white;
}

.download-torremolinos-1{
    padding: 3%;
}

.row-torremolinos-1v2{
    margin-top: 7%;
}

.fase-2-title-torremolinos{
    color: white;
    font-size: 1.7em;
    font-weight: bolder;
    padding: 1%;
    text-align: center;
}

.body-menu-torremolinos-1{
    color: white;
    font-size: 90%;
    padding: 1%;
    text-align: center;
}

.icon-consul-torremolinos{
    width: 7% !important;
}


@media (max-width: 900px){
    .envi-menu-2 .corouse-torremolinos-1{
        height: 290px;
        object-fit: cover;
    }

    .icon-consul-torremolinos{
        width: 30% !important;
    }

    .fase-title-torremolinos{
        color: white;
        font-size: 2.2em;
        font-weight: bolder;
        padding: 1%;
        text-align: center;
    }
}