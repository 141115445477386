.politics .adviceBox h4 {
    text-align: left;
}

.politics img{
    width: 19% !important;
    height: auto;
    display: block;
    margin: auto;
}

.title-rules{
    text-align: center;
    background-color: #007575;
    color: white;
}

.title-rule2{
    padding: 3%;
}

.prem-txt{
    text-align: center;
    font-size: 1.5em;
    padding: 3%;
}

.txt-tit4{
    font-size: 1.5em;
    padding: 2%;
}

.txt-body-rules{
    font-size: 1.5em;
    padding: 3%;
}

.rule-txt{
    color: white;
}

.body-rules{
    background-color: #01b1b2;
}

.rules-card{
    padding-top: 5%;
    padding-bottom: 5%;
}

.title-rule{
    text-align: center;
}

.title-rule2{
    text-align: left;
}

.award1 img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60%;
}

.rule-comp-ico img{
    width: 13%;
    padding: 1%;
}

.rule-comp-ico{    
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

@media (max-width: 768px){
    .award1 img{
        width: 100%;
    }

    .rule-comp-ico img{
        width: 30%;
        padding: 5%;
    }
    
}

.lucky{
    text-align: center;
    padding: 2%;
}

.download-rules{
    text-align: center;
}

.download-rules img{
    padding: 1.5%;
}