.infoBoxLnx .card-text {
    text-align: left;
    color: black;
    font-weight: 500;
    height: 380px;
}

@media (max-width: 1000px){
    .infoBoxLnx .card-text {
        text-align: left;
        color: black;
        height: 100%;
    }
}

.infoBoxLnx a {
    color: black;
    text-decoration: none !important;
}

.infoBoxLnx a:hover {
    color: #262626;  text-decoration: none; transition: all .3s;
}

.infoBoxLnx .card-body {
    background-color:#009fe3;
}


.infoBoxLnx .row{
    margin-bottom: 0% !important;
}

.infoBoxLnx .card-header {
    background-color: #009fe3;
    color: black;
    text-align: center;
}

.infoBoxLnx img {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.row-inci-pr{
    width: 100% !important;
    text-align: center;
}


.infoBoxLnx .btn-outline-dark {
    color: white;
    border-color: white;
    margin: 3%;
}

.infoBoxLnx .btn-primary {
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
}

.infoBoxLnx .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: rgb(23, 99, 100);
    border-color: rgb(23, 99, 100);
}

.infoBoxLnx .card {
    margin-top: 0% !important;
    border: 0px solid rgba(0,0,0,0) !important;
    margin-bottom: 3%;
}

.infoBoxLnx {
    color: #262626;
    text-align: center;
    padding-bottom: 4%;
}

.infoTit{
    padding: 5%;
}

.infoBoxLnx h5 {
    text-align: center;
}

* {
    font-family: 'Montserrat', sans-serif;
}

.download-app-pr h3{
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.qr-app-store{
    width: 65%;
}

.qr-google-play{
    width: 65%;
}