
/**box quien puede utilizarlo**/

.titleb5 h2{
    color:#025555;
    text-align: center;
    margin-bottom: 3%;
    padding-top: 2em;
}

#section-use-it .title-use-card{
    color: #025555;
    text-align: center;
}

#section-use-it .img-gweet-use{
    width: 40%;
    display:block !important;
    margin-top:10% !important;
}

#section-use-it .card{
    margin-bottom: 7%;
}

#section-use-it .img-gweet-use img{
    display:block !important;
    margin:auto !important;
    width: 250%;
    margin-top: 10%;
}

#section-use-it  p{
    color: gray;
    font-size: 14px;
    padding: 4%;
}


/***/

  .Company-Box5 #incident-card p, .row {
      margin-bottom: 0% !important;
  }


/********************* SECTION VIDEO GECOR *********************/
#gweet-page #section-header {
    text-align: center;
}
#gweet-page #section-header .card-titulo {
    padding: 2rem 0 0rem 0;
}
#gweet-page #section-header .container-p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2em 2em;
}
#gweet-page #section-header p {
    text-align: left;
    font-size: 1.3rem;
}
#gweet-page #section-header img {
    height: 25em;
}
#gweet-page #section-header b {
    color: #025555;
}
@media (min-width: 320px) and (max-width: 480px) {
    #gweet-page #section-header {
        text-align: center;
        padding: 0 0rem 0rem 0rem;
    }
    #gweet-page #section-header .card-titulo {
        padding: 2rem 0 0rem 0;
    }

    .titleb5 h2{
        padding-top: 10%;
        padding-bottom: 5%;
    }
}
/********************* SECTION VIDEO GECOR *********************/

/********************* SECTION PROCESS *********************/


#gweet-page #section-process {
    background-color: #025555;
}

#gweet-page #section-process .card-deck {
    padding: 2%;
}

#gweet-page #section-process .card{
    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0em;
}

#gweet-page #section-process .card-img-gweet img {
    display:block;
    margin:auto;
    width: 85%;
}


@media (max-width: 1000px){
    #gweet-page #section-process .card-body {
    height: 100%;
    }
}

#gweet-page #section-process .card-text p {
    margin-top: 9%;
    font-size: 0.8em;
    padding: 2% 0%;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 600;
    color: gray;
}

#gweet-page #section-process .card .gweet-top-bg-1{
    background-image: linear-gradient(45deg, #262626,  #262626);
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center; 
}

#gweet-page #section-process .card .gweet-top-bg-2{
    background-image: linear-gradient(45deg, #262626,  #262626);
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center; 
}

#gweet-page #section-process .card .gweet-top-bg-3{
    background-image: linear-gradient(45deg, #262626,  #262626);
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center; 
}

#gweet-page #section-process .card .gweet-top-bg-4{
    background-image: linear-gradient(45deg, #262626,  #262626);
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center; 
}

#gweet-page #section-process .card .gweet-top-bg-1 b{
    text-align: left;
    padding: 3%;
    color: white;
}

#gweet-page #section-process .card .gweet-top-bg-2 b{
    text-align: left;
    padding: 3%;
    color: white;
}

#gweet-page #section-process .card .gweet-top-bg-3 b{
    text-align: left;
    padding: 3%;
    color: white;
}

#gweet-page #section-process .card .gweet-top-bg-4 b{
    text-align: left;
    padding: 3%;
    color: white;
}

#gweet-page #section-process .card h1{
    padding-bottom: 8%;
}


#gweet-page #section-process .card .title-gweet-card{
    height: 18%;
    color: #025555;
    text-align: center;
    
}


@media (max-width: 768px){

    #gweet-page #section-process .card-deck {
        padding: 2%;
        padding-top: 14%;
    }

    #gweet-page #section-process .card-img-gweet img {
        display:block;
        margin:auto;
        width: 50%;
    }

    #gweet-page #section-header img  {
        display: none;
    }

    #gweet-page #section-header .container-p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 2em 2em;
    }
    
    #gweet-page #section-process .card-text p {
        margin-top: 9%;
        font-size: 20px;
        padding: 2% 0%;
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 600;
        color: gray;
    }

    #gweet-page #section-process .card .title-gweet-card{
        height: 25%;
        font-size: 25px;
        color: #025555;
        text-align: center;
        
    }

    #gweet-page #section-process .card h1{
        padding-bottom: 2%;
    }
    #section-use-it p {
        font-size: 1.4em;
        padding-top: 3%;
        margin-top: 4%;
    }
}


/********************* SECTION PROCESS *********************/

/********************* SECTION USE IT *********************/

#section-use-it img {
    max-width: 30em !important;
}


/********************* SECTION USE IT *********************/




/*************** CARDS HOVER LINK ***************/

.gweet-card-form:hover{
    background-color: #dbf5fa;
    transition: 1s;
    cursor: pointer;
}

