
.legal-advice-container a{    
    color: #ffffff !important;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}
.legal-advice-container a:hover, .legal-advice-container a:focus {
    color: #aaa !important;
    transition: 0.2s;
    background-color: transparent;
}