#survey-detail .icon-ob {  
    height: 0.5em;
    vertical-align: 20%;
}

#question-card .col-form-text{
    font-weight: 600;
}


#question-card .col-form-desc{
    font-size: 1em;
}

#question-card  {  
   padding:0.5em;
}

.range-field  {      
    display: flex;
    padding: 2em 0.5em;
    width: 100%;
}

.limit {      
    padding: auto 0.5em;
}


.range-value {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: #03a9f4;
    color: #fff;
    font-size: 12px;
    display: block;
    transform: translate(40%, 0);
    border-radius: 6px;
  }

  .range-value:after{
    content: "";
    position: absolute;
    top: 7px;
    right: 25px;
    width: 0;
    height: 0;
    border-right: 10px solid #03a9f4;
    border-top: 5px solid transparent;
    border-bottom:5px solid transparent;
  } 
  