
@media (max-width: 768px){
    .notice-box {
        padding: 0% !important;
    }
    .boxContact .container{
        padding-right: 0px;  
         padding-left: 0px;
    } 

    .boxContact .GamesBox{
        padding-right: 0% !important;
        padding-left: 0% !important;    
    }
}

 .notices #incident-card p, .notices .row {
    margin-bottom: 0% !important;
    display: flex;
    justify-content: center;
}
.GamesBox-notice2{
    align-items: flex-start !important;
}   



/*
.notices #incident-card p, .row {
    margin-bottom: 0% !important;
    display: flex;
    justify-content: center;
}
*/