

/********** CAJA EMPRESAS QUE CONTRATAN **********/

.Company-Box5 .svg-inline--fa {
    font-size: 300%;
    color: white;
    display:block;
    margin:auto;
    margin-bottom: 3%;
}

.Company-Box5 h5{
    color: white;
    text-align: center;
}

.Company-Box5 p {
    color: #7f7f7f;
}

.Company-Box5 ul li ul li{
    font-size: 80%;
}

.Company-Box5 .title-b5{
    color: white;
    text-align: center;
    padding: 2% 0;
}


.Company-Box5 #incident-card p, .row {
    margin-bottom: 13px !important;
}

.Company-Box5{
    display:block;
    margin:auto;
    background-color: #025555;
    color: white;
    padding-bottom: 4%;
} 

.Company-Box5 p {
    font-size: 120%;
}



/************ CAJA LUVIA DE ICONOS ************/

.Company-Box6 img {
    width: 100% !important;
    height: auto;
    display:block;
    margin:auto;
}

.Company-Box6 {
    padding-right: 5%;
    padding-left: 5%;
    overflow: hidden;
    max-height: 500px;
}


.Company-Box6 .logos-container {

    text-align: right;
}


.Company-Box6 .logos-scrolling {
    transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    animation: vertical-scroll 20s linear infinite;
    -moz-animation: vertical-scroll 20s linear infinite;
    -webkit-animation: vertical-scroll 20s linear infinite
}

.Company-Box6 .logo{
    height: 75%;
}


@keyframes vertical-scroll {
    100% {
        transform: translateY(0%);
        -moz-transform: translateY(0%);
        -webkit-transform: translateY(0%)
    }
}


.Company-Box6 .logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    width: 100px;
}



.Company-Box6 .logo img {
    width: 10em !important;
    /*max-height: 75%*/
}

.Company-Box6 .title-b6{
    color: #025555;
    text-align: center;
    padding: 5% 0;
}


.Company-Box6 p{
    text-align: left;
}

.logos {
    display: none;
}

@media (max-width: 768px){
    .logos-scrolling{
        display: none;
    }
    .card-deck .card {
        margin-left: auto;
        margin-right: auto;
    }
    .Company-Box4 p {
        padding: 3%;
    }
    .logos {
        display: block;
    }
    .Company-Box6 .logo img {
        width: 4em !important;
    }
    .Company-Box6 {
        padding-right: 5%;
        padding-left: 5%;
        overflow: hidden;
        max-height: 100%;
        margin-bottom: 5%;
    }
}

/*---------------- FIN LOGOS SCROLL ---------------*/

/********************* SECTION COMPANIES *********************/
#section-companies {
    background-color: #025555;
}
/********************* SECTION COMPANIES *********************/

@media (max-width: 768px){
    .schema-company{
        display: none;
    }
}

@media (min-width: 768px){
    .schema-company-r{
        display: none;
    }
}