#new-incident-page {
    padding: 2rem 0 2rem 0;  
    display: flex;
    width: 100%;
    justify-content: center;
}

#new-incident-page .container{
    margin: 0px;
}

#new-incident-page .form-label {
    font-weight: 500;
}

#new-incident-page .form-control {
    border-color: #08B6B9;
    background-color: transparent;
}

.container-button-env {
    width: 100%;
    text-align: right;
}

.boton-env {
    color: #fff;
    background-color: #08B6B9;
    border-color: #08B6B9;
}

.img-newInci {
    width: 100%;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.img-display-newinci {
    display: flex;
}

.img-display-newinci .items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.img-display-newinci .img-container {
    position: relative;
}

.img-container img {
    padding: 2%;
    width: 137px;
    object-fit: cover;
    border-radius: 0.4rem;
    margin-right: 5px;
    height: 137px;
}

.img-container .img-trash {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    border-radius: 50%;
}

.btn-primary:hover.boton-env {
    background-color: #08B6B9;
    border-color: #08B6B9;
    color: white;
}

.hijnLS {
    position: absolute;
    top: 100%;
    font-size: 0.5em;
    left: 0;
    right: 0;
    background: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc;
    z-index: 2;
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #08B6B9;
    transition: width .6s ease;
}

.padding-street.form-row .row {
    margin-left: 0px !important;
}

.boton-enviar {
    padding-left: 0 !important;
}

.margen-col {
    margin-bottom: 1em;
}

.margen-top-col {
    margin-top: 1em;
}

#new-incident-page .title {
    text-align: center;
    font-size: 2em;
    color: #08B6B9;
}

#new-incident-page .container {
    margin-bottom: 1rem;
}

.new-incident-page .btn-primary {
    background-color: #08B6B9 !important;
    border-color: #08B6B9 !important;
    color: white;
}

.new-incident-page .css-uv7j5d {
    width: 145px !important;
    border-radius: 0.25rem !important;
    margin: 0% !important;
    background-color: white !important;
}

.photos-container {
    border: 0;
    padding: 0;
    width: auto;
    height: auto;
}

.css-uv7j5d li {
    border-radius: 0.25rem;
    cursor: pointer;
    border: 1px #08B6B9 solid;
    width: 133px !important;
    height: 133px !important;
    overflow: hidden;
    display: inline-block;
    position: relative;
}

.box-img5 .css-uv7j5d {
    border-radius: 0.25rem !important;
    min-height: auto;
    padding: 0.1rem
}

@media (min-width: 320px) and (max-width: 480px) {
    .boton-env {
        width: 100%;
    }

    .img-display-newinci {
        justify-content: center;
    }
}

.geo-streets {
    max-height: 200px;
    overflow-x: auto;
}

.img-trash svg:not(:root).svg-inline--fa {
    margin-right: 0px;
}

.rp3-margin {
    margin-top: 5%;
}

#new-incident-page .buidingSearch .list-group-item {
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    font-size: 8px;
    color: #515151;
    border-top: 1px solid #08B6B9 !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    margin: 0px;
    background-color: #F0F2F2;
}

#new-incident-page .buidingSearch .list-group-item:hover {
    background-color: #97d3d3;
}

#new-incident-page .buidingSearch .list-group {
    width: 100%;
}


@media (max-width:500px) {

    .img-modal {
        width: 290px !important;
        height: auto;
        max-width: 100%;
    }

}
