.margin-bottom {
    padding-bottom: 4%;
}

.line {
    border-color: white !important;
}

.enviBoxPr .card-text {
    text-align: left;
    color: white;
    height: 175px;
}

@media (max-width: 1000px){
    .enviBoxPr .card-text {
        text-align: left;
        color: white;
        height: 100%;
    }
}


.enviBoxPr a {
    color: white;
    text-decoration: none !important;
}

.enviBoxPr a:hover {
    color: rgb(204, 202, 202);  text-decoration: none; transition: all .3s;
}

.enviBoxPr .card-body {
    background-color:#8B1E3E;
}


.enviBoxPr .row{
    margin-bottom: 0% !important;
}

.enviBoxPr .card-header {
    background-color:#8B1E3E;
    color: white;
    text-align: center;
}

.enviBoxPr img {
    width: 42% !important;
    height: auto;
    display:block;
    margin:auto;
}

.enviBoxPr .btn-primary {
    color: #fff;
    background-color: rgb(26, 141, 143);
    border-color: #8B1E3E;
}

.enviBoxPr .btn-primary:hover, .GamesBox .btn-primary:focus {
    background-color: #8B1E3E;
    border-color: #8B1E3E;
}

.enviBoxPr .card {
    margin-top: 3% !important;
    border: 0px solid rgba(0,0,0,0) !important;
}

.enviBoxPr {
    color: white;
    text-align: center;
}

.enviTit{
    padding: 5%;
}

.enviBoxPr h5 {
    text-align: center;
}

.citimg{
    width: 40%;
}