#survey-header {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: white;
    flex-direction: row;
    align-items: center;    
    text-align: center; 
}

#survey-header .header-title{
    font-size: 2em;
    flex-grow: 2;
    font-weight: 600;
    padding-left: 0.3%;
    padding-right: 0.3%;
}

#survey-header .active{
    font-weight: bold;
}

#survey-header a:hover{ 
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #survey-header .menu-survey{
        text-align: center;
        margin-bottom: 0.5em !important;   
    }
  }

.state-survey-tab{
    width: 30%;
}

.bt-suv-b{
    background-color: #015655;
}

.bt-suv-b:hover{
    background-color: #026d6b;
}

.suv-p2{
    width: 100%;
    margin-top: 2%;
}