#politica-empresa .politics .adviceBox h4 {
    text-align: left;
}


#politica-empresa .politics img{
    width: 19% !important;
    height: auto;
    display: block;
    margin: auto;
}

#politica-empresa.container{
    padding-top: 0rem;
    padding-bottom: 0rem;
}

#politica-empresa .react-pdf__Page__textContent{
    display: none;
}

#politica-empresa .react-pdf__Document{
    padding: 0 34%;
    /*background-color: "#";*/
}

#politica-empresa .pagenumber{
    vertical-align: middle;
    padding: 5px;
}

#politica-empresa .info-pdf{
    padding-top: 1em;
}
#politica-empresa .react-pdf__Page__annotations{
   height: 0;
}

@media (max-width: 900px){
    .react-pdf__Page__canvas{
        width: 100% !important;
        height: auto !important;
    }

    #politica-empresa{
        margin: 0% !important;
        padding: 0% !important;
    }

    #politica-empresa .react-pdf__Document {
        padding: 0% !important;
    }

    .btn-groups{
        text-align: center;
        margin-bottom: 10%;
    }

    .pagenumber{
        text-align: center;
    }
}