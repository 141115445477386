

/********************* SECTION HEADER *********************/


.container-boton-nueva {
    background-color: #025555;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    color: white;
    padding-top: 3%;
    padding-bottom: 2%;
}

.container-boton-nueva img{
    margin-bottom: 3%;
}


.container-boton-nueva .btn-secondary {
    color:white !important;
    background-color: #495057 !important;
    border-color: #495057 !important;
    margin-top: 1%;
    margin-bottom: 2%;
    
}

.container-boton-nueva .btn-secondary:hover , .container-boton-nueva .btn-secondary:focus{
    color:#495057 !important;
    background-color: white !important;
    border-color: white !important;
}

/********************* SECTION HEADER *********************/

/********************* SECTION VIDEO GECOR *********************/
.section-video-gecor {
    text-align: center;
    padding: 0 0rem 5rem 0rem;
}
.section-video-gecor .card-titulo {
    padding: 2rem 0 2rem 0;
    /*text-transform: uppercase;*/
}
.section-video-gecor p {
    text-align: left;
}
@media (min-width: 320px) and (max-width: 480px) {
    .section-video-gecor {
        text-align: center;
        padding: 0 0rem 0rem 0rem;
    }
    .section-video-gecor .video-text {
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .section-video-gecor .card-titulo {
        padding: 2rem 0 2rem 0;
    }
}

.card-titulo {
    color: rgb(27, 150, 150) !important;
    font-weight: 600 !important;
}



@media (min-width: 1281px) {
    .iframe-img {
        height: 22em;
    }   
}
.iframe-titulo {
    color: rgb(0, 0, 0) !important;
    font-weight: 400 !important;
}
.iframe-titulo.card-title {
margin-bottom: 0%;
}



.video-text{
    color: #495057;
    font-size: 120%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top:5%;

}

.video-text b{ 
    color: #01b1b2;
}

/*********************************/

/*********************** SECTION GREENCITIES ********************/
.section-greencities {
    /*padding: 2rem 15rem 0rem 15rem;*/
    text-align: center;
}

.section-greencities img {
    object-fit: cover;
    width: 100%;
    max-height: 35rem;
    height: 25rem;
    padding-right: 0;
   /* -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);*/
}

.section-greencities p{
    font-size: 1.25rem;
}

.section-greencities .btn{
    background-color:#025555;
}
/*********************************************************/

/********************* SECTION DOWNLOAD *********************/
.section-download {
    background-color: #025555;
    /*padding: 2rem 15rem 0rem 15rem;*/
    text-align: center;
}

.col-img-app{
    padding-right: 0% !important;
}
.section-download img {
    object-fit: cover;
    width: 100%;
    max-height: 35rem;    
    -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
}
.section-download p {
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
}
.section-download button {
    margin: 0.4rem;
}
.section-download .google-button {
    background-color: #DB4437;
    border-color: #DB4437;
}
.section-download .google-button:hover {
    background-color: #db645a;
    border-color: #db645a;
}
.section-download .apple-button {
    background-color: rgb(44, 44, 46);
    border-color: rgb(44, 44, 46);
}
.section-download .apple-button:hover {
    background-color: rgb(73, 73, 73);
    border-color: rgb(73, 73, 73);
}
.section-download .new-incidence {
    background-color: transparent;
    border: 2px solid white;
}
.section-download .new-incidence:hover {
    background-color: white;
    color: #01b1b2;
}
.section-download .card-titulo {
    padding: 2rem 0 2rem 0;
    color: white !important;
}
.section-download .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-download .left-container {
    margin-left: 8rem;
}
.section-download .row {
    margin-bottom: 0 !important;
}
@media (min-width: 320px) and (max-width: 1080px) {
    .col-img-app{
        padding: 0% !important;
    }

    .section-download img {
        object-fit: cover;
        width: 100%;
        height: 100%; 
        -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        padding: 0% !important;
    }
}

@media (min-width: 320px) and (max-width: 750px) {
    .section-download img {
        display: none;
    }
}
@media (min-width: 320px) and (max-width: 1080px) {
    
    .section-download .left-container {
        margin-left: 0;
    }
    .section-download .card-titulo {
        padding: 2rem 0 2rem 0;
    }

}
/********************* SECTION DOWNLOAD *********************/


/********************* BANNER FAQ *********************/
#banner-faq {
    height: 10rem;
    background-color: rgb(44, 44, 46);
}
#banner-faq .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
#banner-faq button {
    background-color: transparent;
    border: 2px solid white;
}
#banner-faq button:hover {
    background-color: white;
    color: rgb(44, 44, 46);
}
#banner-faq p {
    color: white;
    font-size: xx-large;
    font-weight: 500;
    flex-grow: 2;
    margin-bottom: 0px;;
}
@media (min-width: 320px) and (max-width: 480px) {
    #banner-faq p {
        font-size: large;
    }
}
/********************* BANNER FAQ *********************/

/********************* BANNER NOTICE *********************/
#banner-notice {
    height: 6rem;
    margin-bottom: 0.8rem;
    background-color: white; 
}
#banner-notice .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-content: center;
}

#banner-notice .container a{
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: none;
}
#banner-notice button {
    color: #01b1b2 !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: transparent;
    border: 2px solid #01b1b2;
}
#banner-notice button:hover {
    background-color: #01b1b2;
    color: white !important;
}
#banner-notice p {
    color: #01b1b2 !important;
    font-size: xx-large;
    font-weight: 500;
    flex-grow: 2;
    margin-bottom: 0px;;
}

@media (min-width: 320px) and (max-width: 480px) {
    #banner-notice p {
        font-size: large;
    }
}
/********************* BANNER NOTICE *********************/




/********************* SECTION NEWS *********************/
#section-news {
    /*padding: 2rem 15rem 0rem 15rem;*/
    text-align: center;
}
#section-news .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#section-news .card-titulo {
    padding: 2rem 0 0 0;
}
#section-news .text {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
#section-news .GamesBox .card-body {
    height: auto !important;
}
/********************* SECTION NEWS *********************/

/*********************modal********************/

.conf-modal .modal-content { 
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.8) !important;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
.conf-modal .modal-dialog {
    margin: auto !important;
}

.conf-modal .modal-dialog {
    max-width: 70%;
    margin: auto !important;
}

.home-modal-check{
    padding: .6em 2em;
}

/*********************modal********************/

@media (max-width: 768px){
    .schema-nr{
        display: none;
    }

    .text-home-head {
        display: none;
    }
}

@media (min-width: 768px){
    .schema-yr{
        display: none;
    }

    .text-home-head p{
        color: white;
    }
}

/**********   HOME TEXT HEADER  ***********/

.text-home-head p{
    color: white;
}

@media (max-width: 1200px){
    #banner-faq p {
        font-size: 1.5em;
    }
}


/************* BANNER COMPETITION ******************/
















.banner-competition{
    background-color: #b23431 ;
    color: white;
}

.compe-img2 img{
    width:100% ;
    height: auto;
    margin-top: 2%;
}

@media (max-width: 768px){

    .concu-btn2{
        padding: 5%;
    }
    .concu-btn1{
        padding: 5%;
    }

    .compe-img2 img{
        -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

@media (max-width: 575px){

    .concu-text2{
        padding-left: 0% !important;
        font-size: 1.55em !important;
    }

}

@media (max-width: 1600px) and (min-width: 1350px){
    .concu-btn2 {
        padding:2%;
    }

    .concu-btn1 {
        padding:2%;
    }

    .concu-text2{
        padding-bottom: 0% !important;
    }

    .compe-img2{
        height: 100% !important;
        width: 100%;
    }

    .compe-img2 img{
        height: 100% !important;
    }
}

@media (max-width: 1350px) and (min-width: 1000px) {

    .concu-btn2 {
        padding-bottom:2%;
    }

    .concu-btn1 {
        padding-bottom:2%;
    }

    .compe-img2 img{
        -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    .concu-text2{
        color: #262626;
        font-weight: bold;
        padding-top: 3%;
        padding-bottom: 2%;
        text-align: center;
        font-size: 2em !important;
    }
}

@media (max-width: 1000px) and (min-width: 575px){

    .concu-text2{
        font-size: 2em !important;
    }
}

.concu-btn2 .btn{
    margin-right: auto;
    margin-left: auto;
    display: block;
    background-color: #495057;
}

.concu-btn1 .btn{
    margin-right: auto;
    margin-left: auto;
    display: block;
    background-color: #495057;
}

.concu-btn2 .btn:hover{
    background-color: aliceblue;
    transition: 1s;
    color: #495057;
    text-decoration: none;
}

.concu-btn1 .btn:hover{
    background-color: aliceblue;
    transition: 1s;
    color: #495057;
    text-decoration: none;
}

.concu-text2{
    padding-left: 5%;
    color: #262626;
    font-weight: bold;
    padding-right: 5%;
    padding-top: 3%;
    text-align: center;
    font-size: 5em;
}

.img-car-mv img{
    display: none;
}

.concu-text2 img{
    width: 100%;
}

.col-comp{
    padding-right: 0% !important;
    padding-left: 0%;
}

.x1x{
    color: yellow;
}

.carousel-ads .carousel-caption {
    height: 100%;
}

@media (max-width: 1350px) and (min-width: 1080px) {

    .concu-btn2 .btn{
        font-size: 1em;
    }

    .concu-btn1 .btn{
        font-size: 1em;
    }

    .concu-btn1{
        padding: 1.5%;
    }

}

@media (max-width: 1080px) and (min-width: 768px) {

    .concu-btn2 .btn{
        font-size: 0.7em;
    }

    .concu-btn1 .btn{
        font-size: 0.7em;
    }

    .concu-btn1{
        padding: 1.5%;
    }

}

@media (max-width: 1080px) and (min-width:684px) {

    .concu-btn2 .btn{
        font-size: 0.5em;
    }

    .concu-btn1 .btn{
        font-size: 0.5em;
    }

    .concu-btn1{
        padding: 1.5%;
    }
}

@media (max-width: 684px) and (min-width:500px) {

    .concu-btn2 .btn{
        font-size: 0.5em;
    }

    .concu-btn1 .btn{
        font-size: 0.5em;
    }

    .concu-btn1{
        padding: 1.5%;
    }
}

.competition-banner-text-title{

    margin-top: 6%;
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    color: #262626;

}

.competition-banner-text .competition-banner-text-title b{

    color:red;

}

.competition-banner-text .p2x{
    color: #262626; 
    font-size: 2em;
    padding-top: 3%;
    padding-left: 9%;
    padding-right: 9%;
}

.competition-banner-text .p2x b{
    color: tomato;
}

.concu-btn3 .btn{
    margin-right: auto;
    margin-left: auto;
    display: block;
    background-color: #495057;
}

.concu-btn3 .btn:hover{
    background-color: tomato;
    transition: 1s;
    color: white;
    text-decoration: none;
}

.notice-title-carrousel{
    font-size: 2.2em;
    margin-top: 5%;
}

.notice-resume-carrousel{
    font-size: 1.3em;
    margin-top: 3%;
}




/********  NOTICE BANNER *************/


@media (min-width:1880px){
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-carrousel-img{
        height: 290px;
    }
    .notice-carrousel-img img{
        width: 430px;
        margin-top: 5%;
    }
}

@media (max-width:1880px) and (min-width:1780px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-carrousel-img img{
        width: 460px;
        margin-top: 5%;
    }
    .competition-banner-text-title{
        font-size: 2.5em;
    }
    .competition-banner-text p{
        font-size: 2em !important;
        padding: 5% !important;
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

@media (max-width:1780px) and (min-width:1490px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 2em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1.1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 400px;
        margin-top: 5%;
    }

    .competition-banner-text-title{
        font-size: 2.2em !important;
    }

    .competition-banner-text p{
        font-size: 2em !important;
        padding: 5% !important;
        margin-left: 11% !important;
        margin-right: 11% !important;
    }

}




@media (max-width:1490px) and (min-width:1350px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.5em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1.1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 350px;
        margin-top: 5%;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 2em !important;
        padding: 5% !important;
        margin-left: 11% !important;
        margin-right: 11% !important;
    }

}


@media (max-width:1350px) and (min-width:1190px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.4em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 360px;
        margin-top: 5%;
    }

    .concu-text2{
        font-size: 4em !important;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 2em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin-left: 11% !important;
        margin-right: 11% !important;
    }


}


@media (max-width:1190px) and (min-width:1050px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.4em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.8em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 320px;
        margin-top: 3%;
    }

    .concu-text2{
        font-size: 4em !important;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.5em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin-left: 11% !important;
        margin-right: 11% !important;
    }

}


@media (max-width:1050px) and (min-width:900px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.6em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 410px;
        margin-top: 3%;
    }

    .concu-btn2 .btn{
        margin-right: auto;
        margin-left: auto;
        display: block;
        font-size: 1em;
        background-color: #495057;
    }

    .concu-text2{
        font-size: 4em !important;
        padding: 3%;
    }

}


@media (max-width:900px) and (min-width:775px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.7em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 350px;
        margin-top: 3%;
    }

    .concu-text2{
        font-size: 3.2em !important;
        padding: 3%;
        margin-top: 10%;
    }
    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.7em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
        margin-left: 11% !important;
        margin-right: 11% !important;
    }

}


@media (max-width:775px) and (min-width:690px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1rs{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.2em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.9em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 300px;
        margin-top: 3%;
    }

    .concu-btn2 .btn{
        margin-right: auto;
        margin-left: auto;
        display: block;
        font-size: 0.8em;
        background-color: #495057;
    }

    .concu-text2{
        font-size: 2.8em !important;
        padding: 3%;
        margin-top: 10%;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.7em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

}

@media (max-width:690px) and (min-width:575px){
    .competition-img-1rs{
        display: none;
    }
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.1em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.7em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        width: 280px;
        margin-top: 3%;
    }

    .concu-text2{
        font-size: 2.4em !important;
        padding: 3%;
        margin-top: 10%;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.3em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

}

@media (max-width:575px) and (min-width:505px) {
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.5em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1.2em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        display: none;
    }

    .concu-btn2 .btn{
        font-size: 0.8em;
    }

    .concu-text2{
        font-size: 2.4em !important;
        padding: 3%;
        margin-top: 20%;
    }

    .x1x{
        font-size: 1.5em !important;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.3em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
    
}

@media (max-width:505px) and (min-width:445px) {
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.3em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 1em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        display: none;
    }

    .concu-btn2 .btn{
        font-size: 0.8em;
    }

    .concu-text2{
        font-size: 2em !important;
        padding: 3%;
        margin-top: 20%;
    }

    .x1x{
        font-size: 1.5em !important;
    }

    .competition-banner-text-title{
        font-size: 1.9em !important;
    }

    .competition-banner-text p{
        font-size: 1.3em !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
    
}

@media (max-width:445px) and (min-width:400px) {
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1.2em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.9em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        display: none;
    }

    .concu-btn2 .btn{
        font-size: 0.8em;
    }

    .concu-text2{
        font-size: 1.7em !important;
        padding: 3%;
        margin-top: 20%;
    }

    .x1x{
        font-size: 1.2em !important;
    }
    
    .competition-banner-text-title{
        font-size: 1.5em !important;
    }

    .competition-banner-text p{
        font-size: 1.1em !important;
    }
}

@media (max-width:400px) and (min-width:375px){
    .competition-img-1pc{
        display: none;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 1em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.8em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        display: none;
    }

    .concu-btn2 .btn{
        font-size: 0.7em;
    }

    .concu-text2{
        font-size: 1.7em !important;
        padding: 3%;
        margin-top: 20%;
    }

    .x1x{
        font-size: 1.2em !important;
    }

    .competition-banner-text-title{
        font-size: 1.5em !important;
    }

    .competition-banner-text p{
        font-size: 1.1em !important;
    }
    
}

@media (max-width:375px){
    .competition-img-1pc{
        display: none;
    }
    .concu-btn1 .btn{
        font-size: 0.8em;
    }
    .competition-img-1ip{
        display: none;
    }
    .competition-img-1mv{
        display: none;
    }
    .notice-title-carrousel{
        font-size: 0.9em;
        margin-top: 5%;
    }
    
    .notice-resume-carrousel{
        font-size: 0.7em;
        margin-top: 3%;
    }
    
    .notice-carrousel-img img{
        display: none;
    }

    .concu-btn2 .btn{
        font-size: 0.7em;
    }

    .concu-text2{
        font-size: 1.5em !important;
        padding: 3%;
        margin-top: 20%;
    }

    .x1x{
        font-size: 1.2em !important;
    }

    .competition-banner-text-title{
        font-size: 1.2em !important;
    }

    .competition-banner-text p{
        font-size: 1.1em !important;
    }
    
}

/************* BANNER CORONAVIRUS V2 ***************/


.title-ad3{
    font-weight: bold;
    font-size: 3em;
    margin-top: 3%;
    color: #262626;
}

.title-ad3 b{
    color: yellow;
}

.body-ad3{
    font-size: 1.6em;
    padding: 0.5%;
    font-weight: bold;
}

.body-ad3 b{
    color: yellow;
}

@media (max-width:1480px) and (min-width:1300px){
    .body-ad3{
        font-size: 1.5em;
        padding: 0.5%;
        font-weight: bold;
    }
}

@media (max-width:1300px) and (min-width:1270px){
    .body-ad3{
        font-size: 1.2em;
        padding: 0.5%;
        font-weight: bold;
    }
}

@media (max-width:1270px) and (min-width:1131px){
    .body-ad3{
        font-size: 1.2em;
        padding: 0.5%;
        font-weight: bold;
    }
}

@media (max-width:1131px) and (min-width:1050px){
    .body-ad3{
        font-size: 1.2em;
        padding: 0.5%;
        font-weight: bold;
    }
}


@media (max-width:1050px) and (min-width:830px){
    .body-ad3{
        font-size: 1.5em;
        padding: 5%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 2.5em;
        margin-top: 3%;
        color: #262626;
    }
}

@media (max-width:840px) and (min-width:720px){
    .body-ad3{
        font-size: 1.4em;
        padding: 3.2%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 2.2em;
        margin-top: 3%;
        color: #262626;
    }
}


@media (max-width:720px) and (min-width:600px){
    .body-ad3{
        font-size: 1em;
        padding: 3%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 1.8em;
        margin-top: 3%;
        color: #262626;
    }
    
}


@media (max-width:600px) and (min-width:575px){
    .body-ad3{
        font-size: 0.9em;
        padding: 3%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 1.2em;
        margin-top: 3%;
        color: #262626;
    }
}

@media (max-width:575px) and (min-width:490px){
    .body-ad3{
        font-size: 1.1em;
        padding: 2%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 1.3em;
        margin-top: 3%;
        color: #262626;
    }
}

@media (max-width:490px) and (min-width:430px){
    .body-ad3{
        font-size: 0.9em;
        padding: 2%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 1.1em;
        margin-top: 3%;
        color: #262626;
    }
}

@media (max-width:430px) and (min-width:380px){
    .body-ad3{
        font-size: 0.8em;
        padding: 2%;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 1.1em;
        margin-top: 3%;
        color: #262626;
    }
}

@media (max-width:380px){
    .body-ad3{
        font-size: 0.8em;
        font-weight: bold;
    }
    .title-ad3{
        font-weight: bold;
        font-size: 0.9em;
        margin-top: 3%;
        color: #262626;
    }
}

/** SUCESS CASES **/

.heading {
    text-align: center;
    color: #454343;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-bottom: 70px;
    text-transform: uppercase;
    z-index: 999;
}
.white-heading{
    color: #ffffff;
}
.heading:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 40px;
    width: 180px;
    border-radius: 4px;
    transform: translateX(-50%);
    /*background: url(https://coolbackgrounds.io/images/backgrounds/index/disco-dba865f1.png);*/
    background-repeat: no-repeat;
    background-position: center;
}
.white-heading:after {
    background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.heading span {
    font-size: 18px;
    display: block;
    font-weight: 500;
}
.white-heading span {
    color: #ffffff;
}
/*-----Testimonial-------*/

.testimonial:after {
    position: absolute;
    top: -0 !important;
    left: 0;
    content: " ";
   /* background: url(https://coolbackgrounds.io/images/backgrounds/index/disco-dba865f1.png);*/
    background-size: 100% 100px;
    width: 100%;
    height: 100px;
    float: left;
    z-index: 99;
}

.testimonial {
    min-height: 375px;
    position: relative;
    background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
        background-size: cover;
}
#testimonial4 .carousel-inner:hover{
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active{
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item{
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators{
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li{
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active{
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar{
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb{
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control{
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3,1,0,1);
  transition: all 0.6s cubic-bezier(0.3,1,0,1);
}
.testimonial4_control_button .carousel-control.left{
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right{
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover{
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header{
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4{
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
    top: 0;
    left: 0;
    right: 0;
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
    margin: auto;
    display: block;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    position: relative;
    border-radius: 50%;
    box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.4;
    margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 22px;
}

.testimonial .carousel {
  padding-bottom:50px;
}
.testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
    width: 35px;
    height: 35px;
}

#section-success-cases {
    text-align: center;
    padding: 0rem 0 0rem 0 !important;
}
/* ------testimonial  close-------*/


