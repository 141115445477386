/***** Footer *****/
.iconFooter:hover {
    color: #08B6B9;
    text-decoration: none;
    transition: all .3s;
}

.linkFooter a {
    color: #aaa;
    text-decoration: none;
}

.linkFooter a:hover,
.linkFooter a:focus {
    color: #08B6B9;
    border: 0;
}

.footer-top {
    padding: 60px 0 0 0;
    text-align: left;
    color: #aaa;
    background-color: #333333;
}

.footer-top h3 {
    padding-bottom: 10px;
    color: #08B6B9;
}

.footer-about img.logo-footer {
    max-width: 74px;
    margin-top: 0;
    margin-bottom: 18px;
}

.footer-about p a {
    border: 0;
}

.footer-about p a:hover,
.footer-about p a:focus {
    border: 0;
}

.footer-contact p {
    word-wrap: break-word;
}

.footer-contact i {
    padding-right: 0px;
    font-size: 18px;
    color: #666;
}

.footer-contact p a {
    border: 0;
    margin-left: 1%;
}

.footer-contact p a:hover,
.footer-contact p a:focus {
    border: 0;
}

.footer-links a {
    color: #aaa;
    border: 0;
}

.footer-links a:hover,
.footer-links a:focus {
    color: #08B6B9;
    transition: all .3s;
}

.footer-bottom {
    padding: 15px 0 17px 0;
    text-align: left;
    color: #aaa;
}

.footer-social {
    padding-top: 3px;
    text-align: right;
}

.footer-social a {
    margin-left: 20px;
    color: #777;
    border: 0;
}

.footer-social a:hover,
.footer-social a:focus {
    color: #08B6B9;
    border: 0;
}

.footer-social i {
    font-size: 24px;
    vertical-align: middle;
}

.footer-copyright {
    padding-top: 5px;
}

.footer-copyright a {
    color: #fff;
    border: 0;
}

.footer-copyright a:hover,
.footer-copyright a:focus {
    color: #aaa;
    border: 0;
}



.logoFooter .img-card {
    height: 2em;
    margin-bottom: 25px;
}

#footer .contact {
    cursor: pointer;
}

.footer-contact a {
    color: #aaa;
    text-decoration: none;
    background-color: transparent;
}

.footer-contact a:hover,
.footer-contact a:focus {
    color: #08B6B9;
    text-decoration: none;
    background-color: transparent;
    transition: all .3s;
}

.ue-container {
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 1em;

}

.whitefooter {
    background-color: white !important;
    color: #262626 !important;
}

.ue-container .d-flex {
    padding-top: 1em;
}

@media (max-width: 768px) {
    .ue-container {
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 0em;
        text-align: center;
        font-size: 0.8em;

    }

    .ue-container .d-flex {
        padding-bottom: 0em;
        display: block;
        margin: auto;
        align-content: center;
        padding-right: 0%;
        padding-top: 0em;
    }

    .ue-container .d-flex img {
        display: block;
        margin: auto;
    }

    hr {
        display: none;
    }

    .ue-log {
        width: 100% !important;
        padding-bottom: 5%;
    }

    .txt-eu {
        padding-top: 3%;
        font-size: 1em;
        padding-bottom: 2%;
    }

    #footer .container {
        padding-left: 1em;
        padding-right: 1em;
        margin-left: 0em;
        margin-right: 0em;
        max-width: max-content;
    }

    .footer-contact,
    .footer-about,
    .footer-links {
        padding-bottom: 1em;
    }


}

.footer-about p a {
    color: #aaa !important;
}

.footer-about .nav-link:hover {
    color: #08B6B9;
    text-decoration: none;
    transition: all .3s;
}

.footer-about .svg-inline--fa.fa-w-20 {
    width: 1.25em;
    margin-right: 8px;
}

.footer-about .nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #aaa;
}

.linkFooter .contact p {
    display: flex;
    justify-content: left;
}

.linkFooter {
    margin-left: 0% !important;
}

.social-icons .svg-inline--fa.fa-w-14,
.social-icons .svg-inline--fa.fa-w-16 {
    width: 1.87em;
    height: auto;
}

.copy-ban {
    background-color: #262626;
    color: white;
    text-align: center;
}

.copy-ban p {
    margin-bottom: 0% !important;
}

.social-icons .svg-inline--fa.fa-w-14:hover {
    cursor: pointer;
    color: #08B6B9;
    transition: 1s;
}

.ue-log {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 40%;
    height: auto !important;
}

.container-eu {
    background-color: #025555;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 1.5em;
}

.txt-eu {
    padding-top: 2%;
}

.prfooter {
    background-color: white;
    color: #852441;
}

.footer-informacion-pr {
    color: #852441 !important;
}

.footer-informacion-Ln {
    color: #009fe3;
}

.badge-ln {
    color: white;
    text-align: center;
}

.list-group li {
    background-color: transparent;
    color: white;
    border: none !important;
}

#footer430 .row p {
    color: #aaa;
    padding-top: 1%;

}

#footer430 .row h3 {
    color: white;
}

#footer430 .row {
    color: #aaa;
}

#footer430 .enlace:hover {
    text-decoration: underline;
}

.banner-foo-grn {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;

}

.footer-banner-granada {
    max-width: 100% !important;
}

.escudo-grn-1 {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 4%;
}

.feder-grn {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 4%;
}

.europ-grn {
    width: 36% !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 4%;
}

.text-foo-ue-2 {
    color: white;
    text-align: center;
    font-size: 16px !important;
}

.ue-log-jr {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 17%;
    margin-top: 1em !important;
}

.ue-container .d-flex {
    padding-top: 0em !important;
}

/********** FOOTER ONIL CSS ********/
#footer10438 {
    .enlace:hover {
        color: #08B6B9;
    }

    a:hover {
        color: #08B6B9 !important;
        text-decoration: none !important;
    }

    a {
        color: white;
    }
}


/********** FOOTER VILADECANS CSS ********/

.footer-informacion-viladecans {
    padding-top:10px;
    padding-bottom: 10px;
    color: #ed1558 !important;
}

.footer-viladecans {
    background-color: #FFFFFF !important;
    color: #262626 !important;
}

.footer-viladecans .footer-contact a {
    color: #262626 !important;
}

.footer-viladecans .footer-contact a:hover {
    color: #ed1558 !important;
    transition: 1s;
}

.footer-viladecans .dropdown-toggle {
    color: #262626 !important;
}

.footer-viladecans .dropdown-toggle:hover {
    color: #ed1558 !important;
    transition: 1s;
}

.footer-viladecans .footer-links a {
    color: #262626 !important;
}

.footer-viladecans .footer-links a:hover {
    color: #ed1558 !important;
    transition: 1s;
}

.footer-informacion-viladecans .social-icons:hover {
    color: #ed1558 !important;
    transition: 1s;
}

/********** FOOTER ADEJE CSS ********/

.footer-informacion-adeje {
    padding-bottom: 10px;
    color: #7763b9 !important;
}


/**** NEW FOOTER CSS ENVIROMENTAL JUNIOR 06-23 *****/

.enviromental-footer {
    padding: 60px 0;
    text-align: left;
    color: white;
    background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/06/2023_6_5_9_46_cool-background%20-%20copia.jpg) !important;
}



/****** END NEW CSS ENVIROMENTAL JUNIOR ********/