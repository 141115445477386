body {
    margin: 0;
  }

  .whateveryouwant {
    position: absolute;
    bottom: 10em;
    right: 5em;
  }

  .whateveryouwant a {
      color: #00aead !important;
      cursor: pointer;
  }
 
  .error-svg {
    display: block;
  }
  
  #window-1 {
    animation: light 17s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-2 {
    animation: light 18s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-3 {
    animation: light 16s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-4 {
    animation: light 19s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-5 {
    animation: light 20s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-6 {
    animation: light 12s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-7 {
    animation: light 18s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-8 {
    animation: light 11s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-9 {
    animation: light 15s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-10 {
    animation: light 20s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-11 {
    animation: light 20s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-12 {
    animation: light 12s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-13 {
    animation: light 18s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-14 {
    animation: light 14s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-15 {
    animation: light 20s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #window-16 {
    animation: light 16s infinite alternate;
    animation-timing-function: steps(2);
  }
  
  #car-3,
  #car-4,
  #car-5,
  #car-7,
  #car-8 {
    transform-origin: center center;
    transform-box: fill-box;
  }
  
  #car-1 {
    animation: car1 30s infinite linear;
  }
  
  #car-2 {
    animation: car2 30s infinite linear;
  }
  
  #car-3 {
    animation: car3 15s infinite linear;
  }
  
  #car-4 {
    animation: car4 15s infinite linear;
  }
  
  #car-5 {
    animation: car5 30s infinite linear;
  }
  
  #car-6 {
    animation: car6 30s infinite linear;
  }
  
  #car-7 {
    animation: car7 15s infinite linear;
  }
  
  #car-8 {
    animation: car8 10s infinite linear;
  }
  
  #car-9 {
    animation: car9 15s infinite linear;
  }
  
  @keyframes car1 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(-23.25%, -150px);
    }
    30% {
      transform: translate(-23.25%, -128px);
    }
    50% {
      transform: translate(0, 22px);
    }
    70% {
      transform: translate(46.5%, 322px);
    }
    80% {
      transform: translate(46.5%, 300px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes car2 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(46.5%, 300px);
    }
    21% {
      transform: translate(46.5%, 278px);
    }
    50% {
      transform: translate(0, -22px);
    }
    70% {
      transform: translate(-23.25%, -172px);
    }
    80% {
      transform: translate(-23.25%, -150px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes car3 {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(70px, -40px);
    }
    11% {
      transform: translate(70px, -40px) rotateY(180deg);
    }
    50% {
      transform: translate(550px, 240px) rotateY(180deg);
    }
    51% {
      transform: translate(70px, 248px) rotateY(180deg);
    }
    75% {
      transform: translate(-180px, 105px) rotateY(180deg);
    }
    76% {
      transform: translate(-180px, 105px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes car4 {
    0% {
      transform: translate(0, 0);
    }
    15% {
      transform: translate(-87.5px, 50px);
    }
    16% {
      transform: translate(-87.5px, 50px) rotateY(180deg);
    }
    31% {
      transform: translate(-206px, -16px) rotateY(180deg);
    }
    32% {
      transform: translate(-206px, -16px);
    }
    47% {
      transform: translate(-80px, -80px);
    }
    74% {
      transform: translate(-90px, -90px) rotateY(180deg);
    }
    89% {
      transform: translate(44px, -24px) rotateY(180deg);
    }
    90% {
      transform: translate(44px, -24px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes car5 {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(150px, 82px);
    }
    52% {
      transform: translate(720px, 82px);
    }
    72% {
      transform: translate(210px, -210px);
    }
    73% {
      transform: translate(210px, -210px) rotateY(180deg);
    }
    94% {
      transform: translate(-75px, -45px) rotateY(180deg);
    }
    95% {
      transform: translate(-75px, -45px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes car6 {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    60% {
      transform: translate(-31%, -200px);
      opacity: 1;
    }
    61% {
      transform: translate(-31%, -200px);
      opacity: 0;
    }
    62% {
      transform: translate(38.25%, 250px);
      opacity: 0;
    }
    63% {
      transform: translate(38.25%, 250px);
      opacity: 1;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes car7 {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    46% {
      transform: translate(460px, 265px);
      opacity: 1;
    }
    47% {
      transform: translate(460px, 265px);
      opacity: 0;
    }
    48% {
      transform: translate(-100px, 45px) rotateY(180deg);
      opacity: 0;
    }
    79% {
      transform: translate(-100px, 45px) rotateY(180deg);
      opacity: 1;
    }
    94% {
      transform: translate(-16px, -6px) rotateY(180deg);
      opacity: 1;
    }
    95% {
      transform: translate(-16px, -6px);
      opacity: 1;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes car8 {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    20% {
      transform: translate(-25px, 16px);
    }
    21% {
      transform: translate(-25px, 16px) rotateY(180deg);
      opacity: 1;
    }
    40% {
      transform: translate(-120px, -34px) rotateY(180deg);
      opacity: 1;
    }
    41% {
      transform: translate(-120px, -34px);
      opacity: 0;
    }
    42% {
      transform: translate(80px, -40px);
      opacity: 0;
    }
    75% {
      transform: translate(80px, -40px);
      opacity: 1;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes car9 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(31%, -200px);
    }
    21% {
      transform: translate(31%, -222px);
    }
    50% {
      transform: translate(0, -22px);
    }
    60% {
      transform: translate(-7.75%, 28px);
    }
    61% {
      transform: translate(-7.75%, 50px);
    }
    90% {
      transform: translate(-7.75%, 50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes light {
    0%,
    49%,
    100% {
      fill: #d5d5d5;
    }
    50%,
    99% {
      fill: #fbf78c;
    }
  }
  

  @media (max-width:1070px){
    .whateveryouwant{
      display: none;
    }
  }