
.photos-incident-body{
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23005e7d' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }

    .modal-body {
        padding: 0 0 0 1rem !important;
       }


    .img-modal {
        height: 250px !important;
        width: auto;
        max-width: 300px;
    } 

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23005e7d' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }

    .carousel-indicators li {
        background-color: #41afb6;
    }

    .carousel-inner {
        height: 300px;
        object-fit: cover;  
    }

    .carousel-indicators  {
        top: 250px;
    }

    .carousel-item img:before {
        /*Solo se muestra en imágenes rotas*/
        display: block;
        box-sizing: border-box;
        content: "" ;
        white-space: pre-wrap;
        position: absolute;
        color:#fff;
        text-align:center;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
        margin-right: 5px;
        min-height: 150px;
        max-height: 150px;
        height: auto;
        background-image: url(https://gecorsystem.blob.core.windows.net/public/Fotos/2023/10/2023_10_31_12_2_no-photo.png);    
        background-color: #fff;    
        background-repeat: no-repeat;
    }
}
