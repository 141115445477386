.cabecera-blog img{
    width: 100%;
}

.blog-menu{
    background-color: #262626;
    color: white;
    height: 50px;
}

.blog-menu .blog-menu-link{
    padding-top: 1%;
}
.blog-menu .blog-menu-link a{
    margin-left:3%;
    margin-right: 3%;
}

.blog-menu .blog-menu-link .url{
    color: rgb(136, 223, 223) !important;
}

/********** Sections ***********/

.blog-sec .sec-box .name-sec-box{
    background-color: teal;
    color: white;
    text-align: center;
    width: 130px;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

