/******************** GLOBAL ************************/
#poi-card {
    padding: 10px 0px 0px 0px;
}
#poi-card .row {
    /*border: solid 1px #e8e8e8;
    border-radius: 5px;*/
    background-color: white;
    transition: top 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: -moz-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    position: relative; 
    top: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 0px 0px rgba(230,218,230,1);
    margin: 0 !important;
}
#poi-card .row:hover {
    position: relative;
    top: -2px;
    cursor: pointer;
    /*-webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);*/
}
#poi-card .choosed {
    position: relative;
    top: -2px;
    /*-webkit-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);
    box-shadow: 0px 0px 5px 0px rgba(230,218,230,1);*/
}
#poi-card div[class^="col"] {
    border-radius: 5px;
    padding: 0;
}
#poi-card .poi {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 600px) {
    #poi-card .poi {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}
/******************** GLOBAL ************************/

/******************** IMAGES ************************/
#poi-card .images {
    max-width: 360px;
    /*padding: 5px;*/
    display: flex;
    flex-direction: row;
}
#poi-card img, .no-img-2 {
    height: 220px;
    width: 360px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
}
#poi-card .no-img-2 {
    background-color: lightgrey;
}
@media only screen and (max-width: 480px) {
    #poi-card .image-after {
        display: none;
    }
}
/******************** IMAGES ************************/

/******************** INFO ************************/
#poi-card .info {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    font-size: 1px !important;
    width: 90%;
    padding-left: 10px;
}
#poi-card .info p {
    margin-bottom: 0px;
}
#poi-card .info-title {
    font-size: 16px;
    font-weight: bold;
}
#poi-card .info-desc {
    font-size: 13px;
    color: #817d7d;
    font-weight: 500;
    overflow: hidden;
    width: 100%;
}
#poi-card .info hr {
    margin-left: 0;
    margin-right: 0;
    width: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

#poi-card .info .horario {
    margin-bottom: 20px;
}

#poi-card .poi-links {
    position: absolute;
    right: 0;
    bottom: 0;
}

#poi-card .poi-links a {
    margin-left: 5px;
}

#poi-card .poi-links .comprar-poi-btn {
    color: #d62828;
    background-color: transparent;
    border-color: #d62828;
    font-weight: 600;
}

#poi-card .poi-links .comprar-poi-btn:hover {
    color: #fff;
    background-color: #d62828;
}

#poi-card .poi-links .navigation-poi-btn {
    color: #003049;
    background-color: transparent;
    border-color: #003049;
    font-weight: 600;
}

#poi-card .poi-links .navigation-poi-btn:hover {
    color: #fff;
    background-color: #003049;
}

@media only screen and (max-width: 480px) {
    #poi-card .info {
        width: 217px;
    }
}

@media only screen and (max-width: 600px) {
    #poi-card .info {
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        font-size: 1px !important;
        width: 100% !important;
        padding-left: 0px;
        padding-top: 10px;
    }

    #poi-card .poi-links {
        position: relative;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: space-evenly;
    }

    #poi-card .info .horario {
        margin-bottom: 0px;
    }

    #poi-card .poi-links a {
        margin-left: 0px;
        font-size: small;
    }

    hr {
        display: block;
    }
}
/******************** INFO ************************/

/******************** DATES ************************/
#poi-card .dates {
    display: flex;
    flex-direction: column;
    width: 266px;
    justify-content: center;
}
#poi-card .dates p {
    margin: 0;
}
@media only screen and (max-width: 480px) {
    #poi-card .info {
        display: flex;
        flex-direction: column;
        font-size: 1px !important;
        width: 90%;
    }

    
}
/******************** DATES ************************/

/******************** ACTIONS ************************/
#poi-card .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
#poi-card .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/******************** ACTIONS ************************/

/******************** STATUS ************************/
@media only screen and (max-width: 480px) {
    #poi-card .status {
        display: none;
    }
}
/******************** STATUS ************************/

/******************** JUNIOR ************************/

.poi-img-jr{
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 3%;
}

@media (min-width: 850px){
    .poi-img-jr{
        max-width: 52%;
    }
}
@media (max-width: 850px){
    .poi-img-jr{
        max-width: 97%;
        margin-bottom: 3%;
    }
}

.poi-img-cnt-jr{
    
}