.containerUser {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    align-content: center;
    margin: auto;
    display: flex;
    justify-content: center;
}

.img-miPerfil {
    margin-top: 5em !important;
    border-radius: 50%;
    margin: 0px 9em;
    width: 230px;
    height: 230px;
    margin-bottom: 2em;
}

.padding-box {
    padding: 3em;
}

.center-miPerfil {
    text-align: center;
}

.title-miPerfil {
    font-size: 3em;
}

.containerUser .input-group-text {
    background-color: rgb(26, 141, 143);
    border-color: rgb(23, 99, 100);
    color: white !important;
    min-width: 200px;
}

.user-details .register-title{
    font-size: 1.5rem;
    font-weight: bold;   
}

.user-details .register-title .a:hover{
    color: #c4ffff Impo !important;
}


.placehol-miPerfil {
    color: white;
}

.containerPerfil .btn-primary {
    background-color: rgb(52, 58, 64);
    border-color: rgb(52, 58, 64);
    color: white;
    width: 13em;
    height: 3em;
    border-radius: 10px;
    float: right;
}

.containerPerfil .btn-primary:hover,
.containerPerfil .btn-primary:focus {
    background-color: rgb(68, 73, 78);
    border-color: rgb(68, 73, 78);
    color: white;
}

.containerPerfil .btn-secondary {
    background-color: rgb(82, 88, 93);
    border-color: rgb(82, 88, 93);
    color: white;
    width: 13em;
    height: 3em;
    border-radius: 10px;
    float:left
}

.containerPerfil .btn-secondary:hover,
.containerPerfil .btn-secondary:focus {
    background-color: #7e888b;
    border-color: #7e888b;
    color: white;
}


.line2 {
    color: white;
    border: 1.3px solid white;
    background-color: white;
}

.containerPerfil .card {
    background-color: rgb(49 49 49 / 46%) !important ;
    color: white !important;
    flex: 1 1 auto;
}

.containerPerfil .input-group-prepend {
    margin-right: -1px;
}

.containerPerfil .form-control {
    color: black !important;
    border: 1.3px solid white;
    background-color:  rgb(194 224 221) !important;
    min-width: 170px;
    max-width: 600px;

}

.containerPerfil .form-control:disabled {
    background-color: #5eb5b58f !important;
    color: white;
}

.loginBtn {
    font-size: 15px;
    max-width: 300px;
}

.box-form {
    width: 80% !important;
}

.box-perfil {
    width: 100% !important;
    margin-left: 15% !important;
}

.profile-pic {
    padding-left: auto;
    padding-right: auto;
    padding-top: 1em;
    padding-bottom: 1em;
    display: block;
    width: 90%;
}

@media only screen and (max-width: 1115px) {
    .padding-box2 {
        padding-left: 0em !important;
        padding-right: 0em !important;
    }

    .padding-box {
        padding: 1em;
    }

    .img-miPerfil {
        margin-top: 1em !important;
        border-radius: 50%;
        margin: 0px 0em;
        width: 190px;
        height: 190px;
        margin-bottom: 2em;
    }
}

.profile-pic {
    padding: 10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .profile-pic {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: none;
    }


    .box-perfil {
        width: 100% !important;
        margin-left: 9% !important;
    }

    .containerPerfil {
        width: 100%;
    }
}

.required {
    border: 1.3px solid red !important;
}

.invalid-feedback {
    color:#eb9fa6;
}


.user-details .form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {    
    color:#eb9fa6;
}
