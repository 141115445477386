#pois-page .card {
    margin-top: 1rem !important;
}
#pois-page .col-left {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 1em;
    overflow-y: auto;
}

#pois-page .col-right {
    padding-right: 2em;
    padding-left: 2em;
    padding-top: 1.6em;
    height: 100vh;
    margin-bottom: 5%;
    overflow-y: auto;
    padding-bottom: 4%;
    /* padding-top:4%; */
}

.spinner-incs {
    margin: 5% 50%;
}

#poi-list-container::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1500px) {
    #poi-card .dates {
        display: -webkit-flex;
        display: none;
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 266px;
        -webkit-justify-content: center;
        justify-content: center;
    }
}

@media only screen and (min-width: 480px) {
    #pois-page .col-left {
        padding-right: 2em;
    }
}
@media only screen and (min-width: 1200px) {
    #pois-page .col-left {
        height: 100vh;
        padding-right: 1em;
        margin-bottom: 5%;
    }
}

@media(max-width:750px){
    .poi .status{
        display: none !important;
    }
}