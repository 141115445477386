

.containerFAQ {
    
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 8% 10%;
}

.link-faq{
    color: #00aead !important;
    cursor: pointer;
}

.containerFAQ .card{
    margin-top: 0% !important;
    border: 0px solid rgba(0,0,0,.125) !important;
}

.containerFAQ .accordion>.card .card-header {
    background-color: #08B6B9;
    color: white;
}

.containerFAQ .accordion>.card .card-header:hover {
    background-color: #0c9496;
    color: white;
    transition: all .5s;
}

.containerFAQ .accordion>.card .card-header:focus {
    background-color: #0c9496;
    color: white;
    transition: all .5s;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #08B6B9;
    color: white;
}

.containerFAQ a{
    color: white;
}

.TituloFAQ{
    color: white;
    padding-top: 40px;
    text-align: center;
     
}
.padding-box-info {
    padding-top: 15px !important;
}

@media  (max-width: 580px) {
    .containerFAQ {
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 8% 0%;
    }
}