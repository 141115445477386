.digitalizador-img{
    width: 100%;
    padding: 2%;
}

.title-fincas{
    font-size: 3em;;
    font-weight: bold;
    text-align: center !important;
}

.digitalizadores-box{
    color: white;
    background-color: #025555;
}

#card-text-categoria .card-body{
   padding: 0 1.25rem 0 1.25rem;
}

.digitalizadores-title-1{
    padding: 2%;
    width: 100%;
    text-align: center;
}

.digitalizadores-title-1 h2{
    color: white !important;
}

.digitalizadores-content{
    padding: 1%;
}

.digitalizadores-card{
    text-align: center;
}

.digitalizadores-card-1{
    background-color: #1a8d8f;
    margin: 1%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.digitalizadores-card-1 .card-header{
    font-weight: bold;
}

.digitalizadores-card-1 p{
    margin-top: 0.75em;
}

.boxContact p{
    text-align: justify;
}
.digitalizadores-card-2{
    background-color: white;
    margin: 1%;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.logo-fincas-img{
    width: 71%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@media (min-width: 1420px){
    .digitalizadores-card .card{
        width: 100% !important;
    }
    .logo-fincas-img{
        width: 71%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .digitalizadores-card-1{
        background-color: #1a8d8f;
        margin: 1%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .digitalizadores-card-2{
        background-color: white;
        margin: 1%;
        height: 250px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

@media (max-width: 1420px){
    .digitalizadores-card .card{
        width: 100% !important;
    }
    .logo-fincas-img{
        width: 71%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    
    .digitalizadores-card-1{
        background-color: #1a8d8f;
        margin: 1%;
        height: auto !important;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-size: 1em !important;
    }

    .digitalizadores-card-2{
        background-color: white;
        margin: 1%;
        height: auto !important;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-size: 1em !important;
    }

}