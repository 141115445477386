/******************** GLOBAL ************************/
#survey-detail {
    background-color: white;
    border-radius: 5px;
}
/******************** GLOBAL ************************/

/******************** HEADER ************************/
#survey-detail .header {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;    
    margin-top: 1em;    
}

#survey-detail .header-title {
    font-size: 1.6em;
    flex-grow: 2;
    font-weight: 600;
    padding-left: 0.3%;
    padding-right: 0.3%;
}


/******************** HEADER ************************/

/******************** INFO ************************/
#survey-detail .info .info-title {
    font-size: 16px;
    font-weight: 600;
}

#survey-detail .info .info-desc {
    font-size: 1em;
    padding:0.5em;

}

/******************** INFO ************************/

/******************** ACTIONS ************************/
#survey-detail .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
#survey-detail .actions div {
    border: solid 2px #7d7d7d;
    color: #7d7d7d;
    border-radius: 5px;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/******************** ACTIONS ************************/

/******************** IMAGES ************************/

#survey-detail .image img{
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
#survey-detail .image {    
    margin-bottom: 1em;
    width: 100%;
    padding: 5px;
    /* display: flex; */
    flex-direction: row;
}
#survey-detail .image img{
    object-fit: cover;
    border-radius: 5px;
    margin-right: 5px;
    min-height: 150px;
    max-height: 300px;
    height: auto;
}

/******************** IMAGES ************************/

#survey-detail .row-url{
    display: flex;
    flex-direction: row;
    font-size: 2em;
    padding: 0;
    margin-right: 5px;
}


#survey-detail .col{
    flex-grow: 0;
    padding-left:0;
}


/******************** HEAD **************************/

.row-hed-survey{
    margin-top: 2%;
    width: 100%;
}

.header-title{
    text-align: center;
    font-weight: bold;
}

.back-survey-btn {
    background-color: #41afb6;
    border: 0;
    margin-top: 6px;
}

.back-survey-btn:hover{
    background-color:#17beca;
}

.container-button{
    padding-right: 30%;
    padding-left: 30%;
}


.survey-btn {
    background-color: #41afb6;
    border: 0;
}

.survey-btn:hover{
    background-color:#17beca;
}

/* @media (min-width: 1200px){

    .back-inc-btn{
        display: none;
    } 

}*/


.error-message{
    text-align: center;
    color: red;
}

.row-hed-survey svg{
    margin-right: 0px !important;
}

.card-survey-commet{
    text-align: left;
}

.img-head-detail{
    width: 100%;
    object-fit: cover;
    height: 17rem;
    
}

.img-head-detail-survey{
    background: rgba(0,0,0,0.5);
    
}